<app-header></app-header>
<pup-content>
  <pup-breadcrumbs home="/" [navigate]="navigate">
    <pup-breadcrumb>{{ 'marketplaces.header.tabs.marketplaces' | translate }}</pup-breadcrumb>
  </pup-breadcrumbs>
  <pup-heading [level]="2">
    {{ 'marketplaces.marketplaces.page-title' | translate }}
    <div class="controls" *ngIf="!loading && regions.length">
      <pup-input
        class="marketplaces--search"
        [clearable]="true"
        [disabled]="loading"
        [value]="filter"
        (valueChange)="onFilterUpdate($event)"
        [placeholder]="'marketplaces.marketplaces.search-marketplace' | translate"
      >
        <pup-icon path="line/ic-search"></pup-icon>
      </pup-input>
      <pup-button (click)="handleAddMarketPlaceClick()" [variant]="ButtonVariant.Secondary">
        {{ "marketplaces.marketplaces.add-marketplace" | translate }}
      </pup-button>
    </div>
  </pup-heading>

  <ng-container *ngIf="!loading">
    <div class="marketplaces-container" *ngIf="regions.length">
      <ng-container *ngFor="let region of filteredRegions">
        <app-region-tag [region]="region"></app-region-tag>
        <pup-cards>
          <pup-card
            (select)="handleMarketplaceSelect(marketplace.id)"
            *ngFor="let marketplace of filteredRegionMarketplaces[region]"
          >
            <pup-paragraph>
              <app-country-tag [marketplace]="marketplace"></app-country-tag>
              <div class="product-count">
                <span *ngIf="marketplace.totalListings !== undefined">
                  {{ marketplace.totalListings }} {{ 'marketplaces.marketplaces.products' | translate }}
                </span>
                <pup-icon path="line/ic-arrow/right" size="32px" color="#2f4255"></pup-icon>
              </div>
            </pup-paragraph>
          </pup-card>
        </pup-cards>
      </ng-container>
    </div>
    <ng-container *ngIf="!regions.length && !filter">
      <pup-placeholder
        message="marketplaces.marketplaces.empty.message"
        description="marketplaces.marketplaces.empty.description"
        imageSrc="/assets/visuals/marketplace.svg"
      >
        <pup-button (click)="handleAddMarketPlaceClick()">
          {{ "marketplaces.marketplaces.add-marketplace" | translate }}
        </pup-button>
      </pup-placeholder>
    </ng-container>

    <ng-container *ngIf="!filteredRegions.length && filter">
      <app-empty-placeholder
        class="empty-placeholder"
        [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
        [emptyPlaceholderMessage]="'marketplaces.errors.no-results.message'"
        [emptyPlaceholderDescription]="'marketplaces.marketplace.not-found.description'"
      >
      </app-empty-placeholder>
    </ng-container>
  </ng-container>

  <pup-loading-visual *ngIf="loading"></pup-loading-visual>
</pup-content>
