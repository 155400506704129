<pup-wizard
  (close)="close()"
  (finish)="finish()"
  class="custom-wizard"
  caption="{{ 'marketplaces.modal.category-select.title' | translate }}"
  finishLabel="{{ 'marketplaces.modal.category-select.save' | translate }}"
>
  <ng-template wizard-page>
    <pup-heading class="category-title" [level]="3"
      >{{'marketplaces.modal.category-select.sub-title' |
      translate}}</pup-heading
    >
    <div class="selection-container">
      <pup-list-select
        (add)="addCategory($event)"
        (addAll)="addAllCategories()"
        (remove)="removeCategory($event)"
        (removeAll)="removeAllCategories()"
        [isSelected]="isSelected"
        [selectedItems]="selectedCategories"
        [availableItems]="filteredCategories"
        labelProp="name"
      >
        <pup-list-select-available
          [labels]="{ containerLabel: 'marketplaces.modal.category-select.available-categories' }"
          [useVirtualScroll]="true"
          [listTemplate]="itemAvailable"
        >
          <pup-input
            class="search-input additional-header"
            [clearable]="true"
            placeholder="marketplaces.modal.category-select.search-categories"
            [value]="filter"
            (valueChange)="onFilterUpdate($event)"
          >
            <pup-icon path="line/ic-search"></pup-icon>
          </pup-input>
          <pup-placeholder
            class="empty-placeholder"
            [variant]="PlaceholderVariant.Categories"
            description="marketplaces.modal.category-select.not-found.description"
            message="marketplaces.modal.category-select.not-found"
          ></pup-placeholder>
        </pup-list-select-available>
        <pup-list-select-selected
          [labels]="{ containerLabel: 'marketplaces.modal.category-select.selected-categories' }"
        >
          <pup-placeholder
            class="empty-placeholder"
            imageSrc="assets/visuals/arrows.svg"
            message="marketplaces.modal.category-select.empty-selected"
            description="marketplaces.modal.category-select.empty-selected-description"
          ></pup-placeholder>
        </pup-list-select-selected>
      </pup-list-select>
    </div>
  </ng-template>
</pup-wizard>

<ng-template #itemAvailable let-item="item">
  <div class="category-item">
    {{item.name}}
    <pup-icon
      class="icon-item"
      path="line/ic-arrow/right"
      *ngIf="!isSelected(item)"
    ></pup-icon>
  </div>
</ng-template>
