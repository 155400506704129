import { Component, ElementRef, OnInit } from '@angular/core';

import { IconAlignment, PlaceholderVariant, SwitchSize, TooltipAlignment, TooltipPlacement } from 'lib-juniper';
import { Attribute } from '@app/interfaces';
import { CategoryService } from '@app/services/category.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Page } from '@app/pages/page';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Switcher } from '@app/components/filter-switcher/filter-switcher.component';
import { LocalStorageService } from '@app/services/local-storage.service';
import { Category } from '@app/interfaces/category';

@Component({
  selector: 'app-product-attributes',
  templateUrl: './attribute-selector.page.html',
  styleUrls: ['./attribute-selector.page.scss', '../list-sector-modal.scss'],
})
export class AttributeSelectorPage extends Page implements OnInit {
  SwitchSize = SwitchSize;

  categories: { id: string; name: string }[] = [];

  TooltipPlacement = TooltipPlacement;
  TooltipAlign = TooltipAlignment;

  selectedAttributes: Attribute[] = [];

  allCategories: boolean = false;

  PlaceholderVariant = PlaceholderVariant;

  filter: string = '';
  filteredAttributes: Attribute[] = [];
  attributes: Attribute[] = [];

  categoryFilter: string = '';
  filteredCategories: { id: string; name: string }[] = [];

  InputIconAlign = IconAlignment;

  loadingAttributes: boolean = false;
  selectedCategory!: { id: string; name: string };

  showCategorySelection: boolean = true;
  filterSelected: boolean = false;

  selectedAttributesGroupFilter: Switcher = 'All';
  availableAttributesGroupFilter: Switcher = 'All';

  isListing: boolean = false;

  constructor(
    public ref: ElementRef,
    public categoryService: CategoryService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public location: Location,
    public localStorageService: LocalStorageService,
  ) {
    super(ref, router, activatedRoute);
  }

  async ngOnInit() {
    const category = this.activatedRoute.snapshot.params?.category;
    if (category) {
      this.isListing = true;
      this.loadCategoryAttributes(category);
      this.selectedAttributes = this.isListing ? this.categoryService.displayedListingAttributes : this.categoryService.displayedProductAttributes;
      this.selectedAttributes = this.selectedAttributes.filter(
        (attr) => !this.categoryService.lockedAttributes.includes(attr)
      );
      this.loading = false;
      this.showCategorySelection = false;
      return;
    }
    this.categoryService.categories
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        if (!categories.length) {
          this.selectedAttributes = [];
          this.loading = false;
          return;
        }
        const isCategorySelected =
          this.categoryService.selectedCategories &&
          this.categoryService.selectedCategories.length;

        this.selectedAttributes = this.showCategorySelection ? this.categoryService.displayedProductAttributes : this.categoryService.displayedListingAttributes;
        this.allCategories = !isCategorySelected;
        this.categories = this.categoryService.selectedCategories;

        this.loading = false;

        if (!this.categories.length) {
          this.selectedAttributes = [];
          this.loading = false;
          return;
        }

        this.selectedCategory = this.categories[0];

        this.loadCategoryAttributes(this.selectedCategory.name);
        this.selectedAttributes = this.selectedAttributes.filter(
          (attr) =>
            !this.categoryService.lockedAttributes.find(
              ({ value }) => value === attr.value
            )
        );
      });
  }

  get availableAttributes() {
    return Boolean(this.filteredAttributes.length);
  }

  get allSelectedAttributes() {
    if (!this.isListing && !this.categories.length) {
      return [];
    }
    return this.selectedAttributes.concat(this.categoryService.lockedAttributes);
  }

  loadCategoryAttributes(category: string) {
    this.loadingAttributes = true;
    this.categoryService.fetchAttributes(category, this.isListing).subscribe((attributes) => {
      this.filteredAttributes = [...attributes];
      this.attributes = attributes;
      this.loadingAttributes = false;
    });
    this.filteredCategories = [...this.categories];
  }

  applyAttributeSelection() {
    if (this.isListing) {
      this.categoryService.displayedListingAttributes = [...this.selectedAttributes];
    } else {
      this.categoryService.displayedProductAttributes = [...this.selectedAttributes];
    }
    this.localStorageService.saveCategoryServiceState();
    this.close();
  }

  close() {
    if (!this.showCategorySelection) {
      this.location.back(); // TODO a better solution is required
      return;
    }
    this.router.navigate(['']);
  }

  addAll() {
    this.selectedAttributes.splice(0, this.selectedAttributes.length);
    this.selectedAttributes.push(...this.attributes);
  }

  removeAll() {
    this.selectedAttributes = [];
  }

  addSingle(attribute: any) {
    this.selectedAttributes.push(attribute);
  }

  removeSingle(attribute: any) {
    this.selectedAttributes.splice(
      this.selectedAttributes.findIndex((i) => i === attribute),
      1
    );
  }

  get lockedAttributes() {
    return this.selectedAttributesGroupFilter === 'All' ? this.categoryService.lockedAttributes : [];
  }

  onFilterUpdate(filter: string) {
    this.filter = filter;
    const query = filter.toLowerCase();

    if (filter && filter.length) {
      this.filteredAttributes = this.attributes.filter(
        (attribute) =>
          attribute.name.toLowerCase().includes(query) ||
          String(attribute.value).toLowerCase().includes(query)
      );
    } else {
      this.filteredAttributes = [...this.attributes];
    }
  }

  onCategoryFilterUpdate(filter: string) {
    this.categoryFilter = filter;
    if (filter && filter.length) {
      this.filteredCategories = this.categories.filter((item) =>
        String(item.name).toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      this.filteredCategories = [...this.categories];
    }
  }

  selectAttributesGroupSwitcher(
    group: 'available' | 'selected',
    selected: Switcher
  ) {
    if (group === 'available') {
      this.filter = '';
      this.availableAttributesGroupFilter = selected;
      this.filteredAttributes =
        selected === 'All'
          ? [...this.attributes]
          : this.attributes.filter(({ isSystem }) => isSystem);
      return;
    }

    this.selectedAttributesGroupFilter = selected;

    if (!this.isListing && !this.categories.length) {
      return;
    }

    this.filterSelected = selected !== 'All';
  }

  handleCategorySelect(category: { id: string; name: string }) {
    if (this.selectedCategory === category) {
      return;
    }
    this.selectedCategory = category;
    this.loadCategoryAttributes(category.name);
  }

  handleReorder(options: { currentIndex: number; previousIndex: number }) {
    const { currentIndex, previousIndex } = options;
    moveItemInArray(this.selectedAttributes, previousIndex, currentIndex);
  }

  isSelected = (item: Attribute) => {
    return !!this.selectedAttributes.find(({ value }) => value === item.value);
  }

  get disabled(): boolean {
    return !this.loadingAttributes && !this.filteredAttributes.length && !this.filter;
  }
}
