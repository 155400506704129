import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ApiClient } from 'lib-platform-common';
import { Marketplace } from '@app/interfaces/marketplace';
import { map } from 'rxjs/operators';
import { getCountryTranslateKey } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { MarketplaceCategory } from '@app/interfaces/marketplaceCategory';

interface ApiMarketplace {
  id: number,
  country: {
    id: number,
    code: string,
    region: string,
  },
  description: string,
  listings_management: boolean,
  order_sync: boolean,
  auth_sync: boolean,
  total_listings?: number,
  active: boolean,
  seller: boolean
}

interface MarketplaceMetaInfo {
  regions: string[],
  marketplacesByRegion: Record<string, Marketplace[]>,
}


@Injectable({
  providedIn: 'root',
})
export class MarketplacesService {

  marketplaces: BehaviorSubject<Marketplace[]> = new BehaviorSubject<Marketplace[]>([]);

  meta: Subject<MarketplaceMetaInfo> = new BehaviorSubject<MarketplaceMetaInfo>({
    regions: [],
    marketplacesByRegion: {},
  });


  selectedMarketplace: Subject<Marketplace | undefined> = new BehaviorSubject<Marketplace | undefined>(undefined);

  constructor(private api: ApiClient, private translateService: TranslateService) {
    this.fetchMarketplaces();
  }

  async fetchMarketplaces() {
    const marketplaces = await this.api.get<ApiMarketplace[]>({ endpoint: 'marketplaces' })
      .pipe(map(this.mapFromApiResponse))
      .toPromise();

    this.marketplaces.next(marketplaces);
    this.meta.next(this.splitToRegions(marketplaces));
  }

  fetchActiveMarketplaces() {
    return this.api.get<ApiMarketplace[]>({ endpoint: 'marketplaces/active' }).pipe(map(this.mapFromApiResponse));
  }

  async selectMarketplace(marketplaceId: number) {
    let marketplaces = this.marketplaces.value;
    if (!marketplaces?.length) {
      await this.fetchMarketplaces();
      marketplaces = this.marketplaces.value;
    }
    this.selectedMarketplace.next(marketplaces.find(({ id }) => marketplaceId === id));
  }

  updateMarketplace(marketplace: Marketplace) {
    const { autoSync, listingsManagement, orderSync } = marketplace;
    return this.api.patch({
      endpoint: `marketplaces/${marketplace.id}`,
      data: {
        autoSync,
        listingsManagement,
        orderSync,
      }
    });
  }

  getAmazonAuthUrl(marketplaceId: number, countryId: number): Observable<string> {
    return this.api.get<{ url: string }>({
      endpoint: `marketplaces/${marketplaceId}/authenticate/amazon/country/${countryId}`
    }).pipe(map(({ url }: { url: string }) => url));
  }

  getMarketplaceCategories(marketplaceId: number): Observable<MarketplaceCategory[]> {
    return this.api.get<MarketplaceCategory[]>({
      endpoint: `marketplaces/${marketplaceId}/categories`
    });
  }

  splitToRegions(marketplaces: Marketplace[]): MarketplaceMetaInfo {
    const regions = marketplaces.reduce((result: string[], { country }) => {
      const { region } = country;
      if (!result.includes(region)) {
        result.push(region);
      }
      return result;
    }, []);

    const regionMarketplaces: Record<string, Marketplace[]> = {};
    marketplaces.forEach((marketplace: Marketplace) => {
      const { country } = marketplace;
      const { region } = country;
      if (!regionMarketplaces[region]) {
        regionMarketplaces[region] = [];
      }
      regionMarketplaces[region].push(marketplace);
    });

    return {
      regions,
      marketplacesByRegion: this.sortMarketplaces(regionMarketplaces),
    };
  }

  private sortMarketplaces(regionMarketplaces: Record<string, Marketplace[]>): Record<string, Marketplace[]> {
    Object.keys(regionMarketplaces).forEach((key: string) => {
      regionMarketplaces[key] = regionMarketplaces[key].sort((a: Marketplace, b: Marketplace) => {
        const countryNameA = this.translateService.instant(getCountryTranslateKey(a.country.code));
        const countryNameB = this.translateService.instant(getCountryTranslateKey(b.country.code));
        return countryNameA.localeCompare(countryNameB);
      });
    });
    return regionMarketplaces;
  }

  private mapFromApiResponse(marketplaces: ApiMarketplace[]): Marketplace[] {
    return marketplaces.map(marketplace => ({
      ...marketplace,
      autoSync: marketplace.auth_sync,
      listingsManagement: marketplace.listings_management,
      orderSync: marketplace.order_sync,
      totalListings: marketplace.total_listings,
      active: marketplace.active,
      seller: marketplace.seller
    }));
  }
}
