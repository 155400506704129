import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JuniperComponent, TooltipPlacement } from 'lib-juniper';
import { Marketplace } from '@app/interfaces/marketplace';
import { getCountryTranslateKey } from '@app/shared/utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-marketplace-flags',
  styleUrls: ['marketplace-flags.component.scss'],
  templateUrl: 'marketplace-flags.component.html'
})
export class MarketplaceFlagsComponent extends JuniperComponent {

  TooltipPlacement = TooltipPlacement;
  @Input()
  marketplaces!: Marketplace[];

  getFlagImageSrc(marketplace: Marketplace) {
    return `/assets/visuals/flags/${marketplace?.country?.code}.svg`
  }

  getCountryTranslateKey(code: string): string {
    return getCountryTranslateKey(code);
  }
}
