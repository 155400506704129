import { Injectable } from '@angular/core';
import Ajv from 'ajv';
import addFormats from "ajv-formats"
import { BehaviorSubject, Subject } from 'rxjs';
import np from 'number-precision';

export interface ValidationError {
  message: string;
  missingProperty?: boolean;
  property?: string;
  path: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ListingValidationService {

  currentErrorCount$: Subject<number> = new BehaviorSubject<number>(0);

  errorCountByGroup$: Subject<{ [key: string]: number }> = new BehaviorSubject<{ [key: string]: number }>({});

  setErrorCount(errorCount: number) {
    this.currentErrorCount$.next(errorCount);
  }

  setErrorCountByMenu(errorCounts: { [key: string]: number }) {
    this.errorCountByGroup$.next(errorCounts);
  }

  validate(data: any, schema: any, schemaMeta: any): ValidationError[] {
    let errors: ValidationError[] = [];

    const ajv = new Ajv({ strict: false, allErrors: true, formats: { date: true, time: true } });
    addFormats(ajv);
    ajv.addMetaSchema(schemaMeta);

    ajv.removeKeyword('multipleOf');

    ajv.addKeyword({
      keyword: 'multipleOf',
      type: 'number',
      compile(schema) {
        return (data) => Number.isInteger(np.divide(data, schema));
      },
      errors: false,
      metaSchema: {
        type: 'number',
      }
    });
    const validate = ajv.compile(schema);
    const valid = validate(data);
    console.log('RAW ERRORS: ', validate.errors);
    if (!valid && validate.errors) {
      errors = validate.errors.map((error) => {
        let property = error.params?.missingProperty;
        const path: string[] = error.instancePath.split('/').filter(item => item.length && item !== '0');
        if (!property) {
          const instancePath = error.instancePath.split('/');
          property = instancePath.pop();
          while ((property === '0' || property === 'value') && instancePath.length) {
            property = instancePath.pop();
          }
        }

        return {
          path,
          property,
          message: error.message || 'unknown error',
          missingProperty: !!error.params?.missingProperty,
        };
      });

    }
    return errors;
  }
}
