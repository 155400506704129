<div class="login-content">
  <div class="form-container">
    <div class="form-content">
      <pup-heading [level]="1" class="serif">{{
        'marketplaces.login.label.login' | translate
      }}</pup-heading>
      <!-- log in -->
      <form (ngSubmit)="login()" [formGroup]="loginForm">
        <pup-paragraph>{{
          'marketplaces.login.message' | translate
        }}</pup-paragraph>
        <div class="inputs">
          <pup-input
            label="{{ 'marketplaces.login.label.email' | translate }}"
            formControlName="email"
            [invalid]="validateField('email')"
          ></pup-input>
          <pup-input
            [type]="InputType.Password"
            formControlName="password"
            [invalid]="validateField('password')"
            label="{{ 'marketplaces.login.label.password' | translate }}"
            validationHint="{{ errorMessage | translate }}"
          >
          </pup-input>
        </div>
        <pup-button
          [type]="ButtonType.Submit"
          class="submit-button"
          [variant]="ButtonVariant.Dark"
          [size]="ButtonSize.Extra"
          [disabled]="loading"
        >
          {{ 'marketplaces.login.label.login' | translate }}
        </pup-button>
      </form>
    </div>
    <a
      href="https://productsup.io/"
      class="powered-by"
      rel="noopener"
      target="_blank"
    >
      <img src="/assets/visuals/logos/dark.svg" alt="Powered by Productsup" />
    </a>
  </div>
</div>

<div class="tapestry"></div>
