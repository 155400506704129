<pup-header>
  <img src="/assets/visuals/home-icon.svg" class="home-icon" routerLink="">

  <pup-menu [horizontal]="true">
    <pup-menu-item [selected]="!router.url.includes('marketplaces')" routerLink="">
      <pup-icon path="line/ic-catalog"></pup-icon>
      {{ "marketplaces.header.tabs.product-catalog" | translate }}
    </pup-menu-item>
    <pup-menu-item [selected]="router.url.includes('marketplaces')" routerLink="/marketplaces">
      <pup-icon path="line/ic-market"></pup-icon>
      {{ 'marketplaces.header.tabs.marketplaces' | translate }}
    </pup-menu-item>
  </pup-menu>
  <div>
    <pup-icon path="solid/ic-question" size="2rem"></pup-icon>
    <pup-dropdown-button [iconTemplate]="avatar" [alignment]="DropdownAlign.Right">
      <pup-dropdown-menu-item>
        <div class="profile-menu">
          <pup-avatar name="User" [size]="AvatarSize.Large"></pup-avatar>

          <pup-button [variant]="ButtonVariant.Link" (click)="logout()">
            <span>{{ 'marketplaces.header.logout' | translate }}</span>
            <pup-icon path="line/ic-arrow/right"></pup-icon>
          </pup-button>
        </div>
      </pup-dropdown-menu-item>
    </pup-dropdown-button>
  </div>
</pup-header>

<ng-template #avatar>
  <pup-avatar name="User"></pup-avatar>
</ng-template>
