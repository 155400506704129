<div>
  <pup-toggle
    [disabled]="disabled"
    [toggled]="allToggled"
    [partial]="true"
    (click)="toggleAll.emit()"
    *ngIf="!hideSelect"
  >
    {{ 'marketplaces.products.select-all' | translate }}
  </pup-toggle>
  <ng-content></ng-content>
</div>
<div [class.wide]="showSelectBox" *ngIf="!disabled">
  <div *ngIf="showSelectBox" class="selection-info-box">
    <span [innerHTML]="selectLabel | translate: { count: selectedCount }"></span>
    <pup-button
      [variant]="ButtonVariant.SimpleLink"
      (click)="selectAll.emit()"
      *ngIf="showSelectAll && !searchResults"
      class="ml-6"
    >
      {{ 'marketplaces.products.selected-all-products' | translate:{ count: total } }}
    </pup-button>
  </div>
  <pup-pagination
    [disabled]="disabled"
    [totalCount]="total"
    [page]="page"
    [perPage]="perPage"
    (pageChange)="pageChange.emit($event)"
    (perPageChange)="perPageChange.emit($event)"
    *ngIf="total > MIN_PAGE_SIZE"
  ></pup-pagination>
</div>

