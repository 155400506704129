import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { JuniperWithValueAccessorComponent } from 'lib-juniper';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-dynamic-date-input',
  styleUrls: ['./dynamic-date-input.component.scss', '../../inputs-common.scss'],
  templateUrl: './dynamic-date-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DynamicDateInputComponent,
    }
  ]
})
export class DynamicDateInputComponent extends JuniperWithValueAccessorComponent {

  value?: Date;

  @Input()
  input?: InputBase;

  @Input()
  invalid: boolean = false;

  @Input()
  hint: string = '';

  writeValue(value: any) {
    if (!value || !value.length) {
      this.value = undefined;
      return;
    }
    if (value instanceof Date) {
      this.value = value;
      return;
    }
    this.value = parseISO(value);
  }

  setDisabledState(disabled: boolean) {
  }

  touch() {
    this.onTouched();
  }
}
