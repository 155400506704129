<div [formGroup]="form" *ngIf="form">

  <div [ngSwitch]="input.controlType">
    <pup-input
      *ngSwitchDefault
      [class.numeric]="input.controlType === InputControlType.Number"
      [required]="input.required"
      [id]="input.key"
      label="{{ input.label | translate }}"
      [formControlName]="input.key"
      [invalid]="!isValid"
      [validationHint]="hint"
      [optional]="!input.required"
      [type]="inputType"
      [resizable]="input.controlType === InputControlType.TextArea"
      [maximumLength]="getAsText(input).maxLength"
      placeholder=""
    >
    </pup-input>
    <div *ngSwitchCase="InputControlType.Image" class="image-container">
      <pup-floating-label>{{ input.label | translate }}</pup-floating-label>
      <img class="thumbnail" default-src [src]="input.value" />
    </div>
    <app-dynamic-toggle-input
      *ngSwitchCase="InputControlType.Toggle"
      [formControlName]="input.key"
      [input]="input"
      [invalid]="!isValid"
      [hint]="hint"
    >
    </app-dynamic-toggle-input>
    <ng-container *ngSwitchCase="InputControlType.Group">
      <div [formGroupName]="input.key">
        <app-dynamic-form-input *ngFor="let item of getGroupItems(input)" [input]="item"
                                [form]="getFormGroup(form.get(input.key))"></app-dynamic-form-input>
      </div>
    </ng-container>
    <app-dynamic-dropdown-input
      *ngSwitchCase="InputControlType.Choice"
      [formControlName]="input.key"
      [items]="getChoiceItems(input)"
      [input]="input"
      [selectedKey]="input.value"
      [invalid]="!isValid"
      [hint]="hint"
    ></app-dynamic-dropdown-input>
    <ng-container *ngSwitchCase="InputControlType.Date">
      <pup-floating-label [optional]="!input.required">{{ input.label }}</pup-floating-label>
      <app-dynamic-date-input
        [input]="input"
        [formControlName]="input.key"
        [invalid]="!isValid"
        [hint]="hint"
      ></app-dynamic-date-input>
    </ng-container>
  </div>

</div>
