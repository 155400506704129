<pup-select [variant]="SelectVariant.Multiple">
  <pup-select-item
    [selected]="isSelected(ProductStatus.NotSubmitted)"
    (select)="handleStatusSelect(ProductStatus.NotSubmitted)"
  >
    {{ "marketplaces.marketplace.products.status-filter.notListed" | translate: { count: status[ProductStatus.NotSubmitted] } }}
  </pup-select-item>
  <pup-select-item
    [selected]="isSelected(ProductStatus.Queued)"
    (select)="handleStatusSelect(ProductStatus.Queued)"
  >
    {{ "marketplaces.marketplace.products.status-filter.queued" | translate: { count: status[ProductStatus.Queued] } }}
  </pup-select-item>
  <pup-select-item
    [selected]="isSelected(ProductStatus.Submitted)"
    (select)="handleStatusSelect(ProductStatus.Submitted)"
  >
    {{ "marketplaces.marketplace.products.status-filter.active" | translate: { count: status[ProductStatus.Submitted] } }}
  </pup-select-item>
  <pup-select-item
    [selected]="isSelected(ProductStatus.PendingFeedback)"
    (select)="handleStatusSelect(ProductStatus.PendingFeedback)"
  >
    {{ "marketplaces.marketplace.products.status-filter.pending-feedback" | translate: { count: status[ProductStatus.PendingFeedback] } }}
  </pup-select-item>
  <pup-select-item
    [selected]="isSelected(ProductStatus.Failed)"
    (select)="handleStatusSelect(ProductStatus.Failed)"
  >
    {{ "marketplaces.marketplace.products.status-filter.failed" | translate: { count: status[ProductStatus.Failed] } }}
  </pup-select-item>
</pup-select>
<pup-button class="clear-button" [variant]="ButtonVariant.DarkLink" (click)="clearSelected()" [disabled]="!selectedStatusList.length">
  <pup-icon path="line/ic-delete"></pup-icon>
  {{ 'marketplaces.marketplace.products.status-filter.clear-status' | translate }}
</pup-button>
