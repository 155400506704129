import { TableSortingState } from '@app/services/ui-state.service';
import { ExtendedParams } from 'lib-platform-common/interfaces';

const COUNTRY_TRANSLATE_KEY_PREFIX = 'marketplaces.marketplaces.country';

export const getCountryTranslateKey = (countryCode: string) => `${COUNTRY_TRANSLATE_KEY_PREFIX}.${countryCode}`;

// TODO this will need rewrite once backend api supports more sorting options
export const extendParamsWithSorting = (params: ExtendedParams, state?: TableSortingState) => {
  if (!state || !Object.keys(state).length) {
    return;
  }
  const firstKey = Object.keys(state)[0];

  params.sortBy = firstKey;
  params.orderBy = state[firstKey].ascending ? 'asc' : 'desc';
};
