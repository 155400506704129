import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ProductsService } from '@app/services/products.service';
import { ButtonVariant, ModalService, ToastService } from 'lib-juniper';
import {
  MarketplaceSelectModal
} from '@app/components/modals/marketplace-selection/marketplace-select-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { PageWithProducts } from '@app/pages/page-with-products';
import { Marketplace } from '@app/interfaces/marketplace';
import { Pagination, Product } from '@app/interfaces';
import { CategoryService } from '@app/services/category.service';
import { ListingsService } from '@app/services/listings.service';
import { LocalStorageService } from '@app/services/local-storage.service';
import { UiStateService } from '@app/services/ui-state.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-products-page',
  templateUrl: './products.page.html',
  styleUrls: ['../page.scss', './products.page.scss'],
})
export class ProductsPage extends PageWithProducts implements OnInit {

  regions: string[] = [];
  regionMarketplaces: Record<string, Marketplace[]> = {};
  categories!: { id: string, name: string }[];
  queryResults: boolean = true;

  ButtonVariant = ButtonVariant;

  constructor(
    private productsService: ProductsService,
    private marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    public modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService,
    categoryService: CategoryService,
    router: Router,
    ref: ElementRef,
    public cdr: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public uiStateService: UiStateService,
  ) {
    super(ref, router, categoryService, modalService, cdr, activatedRoute, localStorageService);
    categoryService.categories.pipe(takeUntil(this.destroyed$)).subscribe((categories) => {
      this.categories = categories;
    });
  }

  async ngOnInit() {
    const marketplaces: Marketplace[] = await this.marketplacesService.fetchActiveMarketplaces().toPromise();
    const { regions, marketplacesByRegion } = this.marketplacesService.splitToRegions(marketplaces);

    this.regions = regions;
    this.regionMarketplaces = marketplacesByRegion;
    this.loadProducts();
  }

  get disabled(): boolean {
    return this.loading || (!this.selectedProductIds.length && !this.allProductsSelected) || !this.regions?.length;
  }

  loadProducts() {
    this.loading = true;
    this.cdr.detectChanges();

    if (this.categoryService.selectedCategories?.length) {
      this.productsService.getProductsByCategory({
        categories: this.categoryService.selectedCategories.map(({ name }) => name),
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        query: this.query,
        sorting: this.uiStateService.state?.sorting?.products
      }).subscribe(this.handleProductsLoad, this.handleError);
      return;
    }

    this.productsService.getProducts({
      pageSize: this.pagination.pageSize,
      currentPage: this.pagination.currentPage,
      query: this.query,
      sorting: this.uiStateService.state?.sorting?.products
    }).subscribe(this.handleProductsLoad, this.handleError);
  }

  handleProductsLoad = (body: { data: Product[], meta: Pagination }) => {
    this.products = body.data;
    this.pagination = body.meta;
    this.loading = false;
    this.searchResults = !!this.query.length;

    this.queryResults = !(this.query && !this.products.length);

    this.cdr.detectChanges();
  };

  handleSortingChange(change: { ascending: boolean, column: string }) {
    this.uiStateService.setProductsSorting(change.column, change.ascending);
    this.loadProducts();
  }

  handleError = (error: unknown) => {
    this.loading = false;
  };

  async addToMarketplace() {
    const count = this.allProductsSelected ?
      this.pagination.totalCount - this.selectedProductIds.length :
      this.selectedProductIds.length;

    const modalRef = this.modalService.open(MarketplaceSelectModal);
    modalRef.instance.regions = this.regions;
    modalRef.instance.regionMarketplaces = this.regionMarketplaces;
    modalRef.instance.count = count;

    const selectedMarketplaces: number[] = await modalRef.result;
    this.loading = true;
    this.cdr.detectChanges();

    for (const marketplaceId of selectedMarketplaces) {
      await this.listingsService.listMultipleProducts(
        marketplaceId,
        this.selectedProductIds,
        this.allProductsSelected,
        this.categoryService.selectedCategories.map(({ name }) => name),
      ).toPromise();
    }

    this.toastService.success(this.translateService.instant(
      'marketplaces.toast.add-to-marketplace-success',
      {
        products: count,
        marketplaces: selectedMarketplaces.length,
      }
    ));

    // unselect everything
    this.selectedProductIds = [];
    this.allProductsSelected = false;

    this.loadProducts();
  }

  openProductDetails(product: Product) {
    this.router.navigate(['products', product.id]);
  }
}
