import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonVariant, ImagePlaceholderVariant, PlaceholderVariant } from 'lib-juniper';

import { TableBaseComponent } from '@app/components/tables/table-base.component';
import { Error, ErrorByListing } from '@app/interfaces/errors';

@Component({
  selector: 'app-listing-errors-table',
  templateUrl: './listing-errors.component.html',
  styleUrls: ['../table.scss', './listing-errors.component.scss'],
})
export class ListingErrorsComponent extends TableBaseComponent<ErrorByListing> {

  PlaceholderVariant = PlaceholderVariant;
  ImagePlaceholderVariant = ImagePlaceholderVariant;
  ButtonVariant = ButtonVariant;

  expandedRows: number[] = [];

  @Output()
  selectError = new EventEmitter<number>();

  handleSelectError(errorId: number) {
    this.selectError.emit(errorId);
  }

  toggleRowExpand(event: Event, rowIndex: number) {
    event.stopPropagation();
    const index = this.expandedRows.indexOf(rowIndex);
    if (index === -1) {
      this.expandedRows.push(rowIndex);
    } else {
      this.expandedRows.splice(index, 1);
    }
  }
}
