<app-marketplace-sidebar [marketplace]="marketplace">
  <div class="header">
    <pup-breadcrumbs home="/" [navigate]="navigate" *ngIf="marketplace">
      <pup-breadcrumb [url]="'../..'">
        <span>{{ 'marketplaces.breadcrumbs.marketplaces' | translate }}</span>
      </pup-breadcrumb>
      <pup-breadcrumb [url]="'..'">
        <span>{{ marketplace.description }} ({{ marketplace.country.code }})</span>
      </pup-breadcrumb>
      <pup-breadcrumb>{{ 'marketplaces.marketplace-errors.page-title' | translate }}</pup-breadcrumb>
    </pup-breadcrumbs>
    <pup-heading [level]="2">{{ 'marketplaces.marketplace-errors.page-title' | translate }}</pup-heading>

    <pup-paragraph class="controls">
      <pup-input
        class="mr-6"
        [clearable]="true"
        [disabled]="loading"
        [(value)]="query"
        (enter)="loadErrors()"
        (clear)="loadErrors()"
      >
        <pup-icon path="line/ic-search" (click)="loadErrors()"></pup-icon>
      </pup-input>
      <span translate>marketplaces.marketplace-errors.sorty-by</span>
      <pup-button
        [variant]="errorType === ErrorType.Listings ? ButtonVariant.Primary : ButtonVariant.Supporting"
        class="error-type-switch"
        (click)="toggleErrorType(ErrorType.Listings)"
      >
        {{ 'marketplaces.marketplace-errors.page-title.error-type-listings' | translate }}
      </pup-button>
      <pup-button
        [variant]="errorType === ErrorType.ErrorCodes ? ButtonVariant.Primary : ButtonVariant.Supporting"
        class="error-type-switch"
        (click)="toggleErrorType(ErrorType.ErrorCodes)"
      >
        {{ 'marketplaces.marketplace-errors.page-title.error-type-codes' | translate }}
      </pup-button>
    </pup-paragraph>
  </div>
  <pup-content>
    <app-errors-table
      *ngIf="errorType === ErrorType.ErrorCodes"
      [items]="codeErrors"
      [loading]="loading"
      [(marked)]="markedErrors"
      [total]="pagination.totalCount"
      (paginationChange)="handlePaginationChange($event)"
      (selectErrorId)="openProductErrorDetails($event)"
      [placeholderImgSrc]="'/assets/visuals/placeholders/empty_listing-errors.svg'"
      [noProductsDescriptionKey]="'marketplaces.listing-errors.table.empty-description'"
      [noProductsMessageKey]="'marketplaces.listing-errors.table.empty-message'"
      [queryResults]="queryResults"
      [sorting]="uiStateService.state?.sorting?.errorsCode"
      (sortingChange)="handleSortingChange($event)"
    >
    </app-errors-table>

    <app-listing-errors-table
      *ngIf="errorType === ErrorType.Listings"
      [items]="listingErrors"
      [loading]="loading"
      [(marked)]="markedErrors"
      [total]="pagination.totalCount"
      (paginationChange)="handlePaginationChange($event)"
      (selectError)="openProductErrorDetails($event)"
      [placeholderImgSrc]="'/assets/visuals/placeholders/empty_listing-errors.svg'"
      [noProductsDescriptionKey]="'marketplaces.listing-errors.table.empty-description'"
      [noProductsMessageKey]="'marketplaces.listing-errors.table.empty-message'"
      [queryResults]="queryResults"
      [sorting]="uiStateService.state?.sorting?.errorsProduct"
      (sortingChange)="handleSortingChange($event)"
    ></app-listing-errors-table>

  </pup-content>
</app-marketplace-sidebar>
