import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Marketplace } from '@app/interfaces/marketplace';
import { ToggleVariant } from 'lib-juniper';

enum MarketplaceProp {
  ListingManagement,
  AutoSync,
  OrderManagement,
}

@Component({
  selector: 'app-marketplace-settings',
  templateUrl: './marketplace-settings.component.html',
  styleUrls: ['./marketplace-settings.component.scss']
})
export class MarketplaceSettingsComponent {

  MarketplaceProp = MarketplaceProp;

  @Input()
  marketplace?: Marketplace;

  @Output()
  marketplaceChange = new EventEmitter<Marketplace>();

  ToggleVariant = ToggleVariant;

  toggleMarketplaceProp(prop: MarketplaceProp) {
    if (!this.marketplace) {
      return;
    }
    switch (prop) {
      case MarketplaceProp.AutoSync:
        this.marketplace.autoSync = !this.marketplace.autoSync;
        break;
      case MarketplaceProp.OrderManagement:
        this.marketplace.orderSync = !this.marketplace.orderSync;
        break;
      default:
        this.marketplace.listingsManagement = !this.marketplace.listingsManagement;
        break;
    }
    this.marketplaceChange.emit(this.marketplace);
  }
}
