<form [formGroup]="form" #formElement>

  <app-error-notification
    *ngIf="listingValidationService.currentErrorCount$ | async as errorCount"
    [message]="'marketplaces.listings.details.errors.missing-required' | translate: { count: errorCount }"
  ></app-error-notification>

  <pup-heading [level]="3">
    {{ 'marketplaces.products.details.sections.main-data' | translate }}
  </pup-heading>
  <div *ngFor="let input of images" class="form">
    <app-dynamic-form-input [input]="input" [form]="form"></app-dynamic-form-input>
  </div>

  <div *ngFor="let group of imageGroups" class="form">
    <app-image-group [images]="group"></app-image-group>
  </div>
  <!--pup-heading [level]="3">{{ 'marketplaces.products.details.sections.main-data' | translate }}</pup-heading-->

  <div *ngFor="let input of nonGroupInputs" class="form">
    <app-dynamic-form-input [input]="input" [form]="form"></app-dynamic-form-input>
  </div>

  <div *ngFor="let input of groupInputs" class="form">
    <pup-heading [level]="3">{{ input.label }}</pup-heading>
    <app-dynamic-form-input [input]="input" [form]="form"></app-dynamic-form-input>
  </div>


</form>

