import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { Observable } from 'rxjs';
import { Error } from '@app/interfaces/errors';
import { ListingsService } from '@app/services/listings.service';
import { takeUntil } from 'rxjs/operators';
import { Marketplace } from '@app/interfaces';
import { ToastService, TooltipPlacement } from 'lib-juniper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-marketplace-error-details-page',
  templateUrl: './marketplace-error-details.page.html',
  styleUrls: ['../../page.scss', '../marketplace.page.scss', './marketplace-error-details.page.scss']
})
export class MarketplaceErrorDetailsPage extends MarketplacePage implements OnInit {

  errorDetails$!: Observable<Error[]>;
  marketplace?: Marketplace;
  errorId: number | undefined;
  TooltipPlacement = TooltipPlacement;

  constructor(
    public router: Router,
    ref: ElementRef,
    public marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    public activatedRoute: ActivatedRoute,
    public toastService: ToastService,
    public translateService: TranslateService,
  ) {
    super(ref, router, marketplacesService, activatedRoute);
  }

  ngOnInit(): void {
    this.errorId = this.activatedRoute.snapshot.params.errorId;

    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe((marketplace) => {
      if (marketplace) {
        this.marketplace = marketplace;
        this.errorDetails$ = this.listingsService.fetchProductErrorDetailsCode(this.errorId, this.marketplace.id);
      }
    });
  }

  copySubmissionIdClick(id: string) {
    navigator.clipboard.writeText(id).then(
      () => this.toastService.success(this.translateService.instant('marketplaces.marketplace-errors.error-details.submission-id-copied'))
    );
  }
}
