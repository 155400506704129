import { Component, Input } from '@angular/core';
import { InputType } from 'lib-juniper';

import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { GroupInput } from '@app/components/dynamic-form/inputs/input-group';
import { ChoiceInput, ChoiceInputType } from '@app/components/dynamic-form/inputs/input-choice';
import { InputControlType } from '@app/interfaces';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TextInput } from '@app/components/dynamic-form/inputs/input-text';

@Component({
  selector: 'app-dynamic-form-input',
  styleUrls: ['./dynamic-form-input.component.scss', './inputs-common.scss'],
  templateUrl: './dynamic-form-input.component.html'
})
export class DynamicFormInputComponent {

  InputControlType = InputControlType;

  @Input() input!: InputBase;
  @Input() form!: FormGroup;
  @Input() group?: string;

  get isValid() { return this.form.controls[this.input.key]?.valid; }

  get hint() { return this.form.controls[this.input.key]?.getError('invalid'); }

  get inputType(): InputType {
    if (this.input.controlType === InputControlType.TextArea) {
      return InputType.Textarea;
    }
    return InputType.Text;
  }

  getGroupItems(group: InputBase): InputBase[] {
    return Object.values((group as GroupInput).groupItems);
  }

  getAsGroup(group: InputBase): GroupInput {
    return group as GroupInput;
  }

  getAsText(input: InputBase): TextInput {
    return input as TextInput;
  }

  getChoiceItems(choice: InputBase): ChoiceInputType[] {
    return (choice as ChoiceInput).choices;
  }

  getFormGroup(group: AbstractControl | null): FormGroup {
    if (group === null) {
      return new FormGroup({});
    }
    return group as FormGroup;
  }
}
