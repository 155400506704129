import { Component, ElementRef, OnInit } from '@angular/core';
import {
  IconAlignment,
  PlaceholderVariant,
} from 'lib-juniper';
import { Page } from '@app/pages/page';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '@app/services/category.service';
import { Location } from '@angular/common';
import { LocalStorageService } from '@app/services/local-storage.service';
import { takeUntil } from 'rxjs/operators';

interface CategoryItem {
  id: string;
  name: string;
}

@Component({
  selector: 'app-product-categories',
  templateUrl: './category-selector.page.html',
  styleUrls: ['./category-selector.page.scss', '../list-sector-modal.scss'],
})
export class CategorySelectorPage extends Page implements OnInit {

  categories!: CategoryItem[];

  selectedCategories!: CategoryItem[];

  filter: string = '';
  filteredCategories: CategoryItem[] = [];

  InputIconAlign = IconAlignment;
  PlaceholderVariant = PlaceholderVariant;

  constructor(
    public ref: ElementRef,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public categoryService: CategoryService,
    public location: Location,
    public localStorageService: LocalStorageService,
  ) {
    super(ref, router, activatedRoute);
  }

  ngOnInit() {
    this.categoryService.categories.pipe(takeUntil(this.destroyed$)).subscribe((categories) => {
      this.categories = categories;
      this.filteredCategories = [...this.categories];
      this.selectedCategories = [...this.categoryService.selectedCategories];
    });
  }

  close() {
    this.router.navigate(['']);
  }

  finish() {
    this.localStorageService.clearProductSelections();
    this.categoryService.selectedCategories = [...this.selectedCategories];
    this.localStorageService.saveCategoryServiceState();
    this.close();
  }

  addAllCategories() {
    this.selectedCategories.splice(0, this.selectedCategories.length);
    this.selectedCategories.push(...this.filteredCategories);
  }

  removeAllCategories() {
    this.selectedCategories.splice(0, this.selectedCategories.length);
  }

  addCategory(category: { id: string, name: string }) {
    this.selectedCategories.push(category);
  }

  removeCategory(category: { id: string, name: string }) {
    this.selectedCategories.splice(this.selectedCategories.findIndex(i => i === category), 1);
  }

  onFilterUpdate(filter: string) {
    this.filter = filter;
    if (filter && filter.length) {
      this.filteredCategories = this.categories.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()));
    } else {
      this.filteredCategories = [...this.categories];
    }
  }

  isSelected = (item: CategoryItem) => {
    if (!this.selectedCategories) {
      return false;
    }
    return !!this.selectedCategories.find(({name}) => name === item.name);
  }
}
