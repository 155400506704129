<ng-container *ngIf="marketplace">
  <pup-paragraph>
    <div class="feature-container">
      <div class="toggle-container">
        <span>{{'marketplaces.add-marketplace.features.listing.title' | translate}}</span>
        <pup-toggle
          [variant]="ToggleVariant.Switch"
          [toggled]="marketplace.listingsManagement"
          (toggle)="toggleMarketplaceProp(MarketplaceProp.ListingManagement)"
        ></pup-toggle>
      </div>
      <span
        class="feature-description"
        [innerHTML]="'marketplaces.add-marketplace.features.listing.body' | translate"
      ></span>
    </div>
  </pup-paragraph>
  <div class="separator">
    <hr><span>{{'marketplaces.add-marketplace.features.coming-soon' | translate}}</span><hr>
  </div>
  <pup-paragraph>
    <div class="feature-container">
      <div class="toggle-container">
        <span>{{'marketplaces.add-marketplace.features.listing-updates.title' | translate}}</span>
      </div>
      <span class="feature-description">
            {{'marketplaces.add-marketplace.features.listing-updates.body' | translate}}
          </span>
    </div>
  </pup-paragraph>
  <pup-paragraph>
    <div class="feature-container">
      <div class="toggle-container">
        <span>{{'marketplaces.add-marketplace.features.order-sync.title' | translate}}</span>
      </div>
      <span class="feature-description">
            {{'marketplaces.add-marketplace.features.order-sync.body' | translate}}
          </span>
    </div>
  </pup-paragraph>
</ng-container>
