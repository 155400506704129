<pup-input
  class="category--search"
  [placeholder]="'marketplaces.listings.search-category' | translate"
  [value]="filter"
  [clearable]="true"
  (valueChange)="onFilterUpdate($event)"
>
  <pup-icon path="line/ic-search"></pup-icon>
</pup-input>
<pup-list *ngIf="filteredCategories.length">
  <pup-simple-list-item
    *ngFor="let category of filteredCategories"
    [selected]="category === selectedCategory"
    (select)="selectedCategoryChange.emit(category)"
  >
    <div class="category-container">
      <span class="category-name" *ngIf="!isOverflown(category.categoryName)">{{ category.categoryName }}</span>
      <span class="category-name" *ngIf="isOverflown(category.categoryName)" [tooltip]="category.categoryName">
        {{ category.categoryName }}
      </span>
      <span class="listing-count">
        {{ 'marketplaces.listings.category-filter.listing-count' | translate: { count: category.listingCount } }}
      </span>
    </div>
    <app-readiness-indicator [ready]="category.ready"></app-readiness-indicator>

  </pup-simple-list-item>
</pup-list>
<pup-placeholder
  *ngIf="!filteredCategories.length && !filter"
  [variant]="PlaceholderVariant.Categories"
  [description]="'marketplaces.categories-filter.not-found' | translate"
  message=""
></pup-placeholder>

<pup-paragraph *ngIf="!filteredCategories.length && filter" class="no-results-filter">
  <span>
      {{ 'marketplaces.listings.category-filter.not-found' | translate: { filter: filter } }}
  </span>
</pup-paragraph>

