<app-table-controls
  [allToggled]="allSelected || marked.length > 0"
  [disabled]="!items.length || loading"
  [hideSelect]="true"
  [showSelectBox]="allSelected || marked.length > 0"
  [showSelectAll]="isSelectAllVisible"
  [selectedCount]="allSelected ? (total - marked.length) : marked.length"
  [selectLabel]="selectedLabel"
  [total]="total"
  [(page)]="page"
  [(perPage)]="perPage"
  (selectAll)="selectAll()"
  (toggleAll)="toggleAllOnPage()"
>
  <ng-content select=".actions"></ng-content>
</app-table-controls>
<pup-table *ngIf="!loading" class="collapsible-table">
  <pup-table-head>
    <pup-table-cell></pup-table-cell>
    <pup-table-cell
      [sortable]="true"
      (sortClick)="setColumnSorting($event, 'sku')"
      [ascending]="sorting?.sku?.ascending"
      [descending]="sorting?.sku?.descending"
    >
      {{ 'marketplaces.products.columns.sku' | translate }}
    </pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.products.columns.image' | translate }}</pup-table-cell>
    <pup-table-cell
      [sortable]="true"
      (sortClick)="setColumnSorting($event, 'title')"
      [ascending]="sorting?.title?.ascending"
      [descending]="sorting?.title?.descending"
    >
      {{ 'marketplaces.products.columns.title' | translate }}
    </pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.errors.table.error-code' | translate }}</pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.errors.table.error-description' | translate }}</pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.errors.table.submission-id' | translate }}</pup-table-cell>
    <pup-table-cell
      [sortable]="true"
      (sortClick)="setColumnSorting($event, 'submissionTimestamp')"
      [ascending]="sorting?.submissionTimestamp?.ascending"
      [descending]="sorting?.submissionTimestamp?.descending"
    >{{ 'marketplaces.errors.table.submission-timestamp' | translate }}</pup-table-cell>
  </pup-table-head>

    <ng-container *ngIf="items.length > 0 && queryResults; else empty">
      <ng-container *ngFor="let listing of items; let listingIndex = index">
        <ng-container *ngFor="let error of listing.errors; let errorIndex = index">
          <pup-table-row
            *ngIf="errorIndex < 1 || expandedRows.includes(listingIndex)"
          >
            <pup-table-cell [class.blank]="errorIndex > 0" class="control-cell">
              <ng-container *ngIf="errorIndex < 1">
                <pup-icon
                  *ngIf="errorIndex === 0 && listing.errors.length > 1"
                  path="{{ expandedRows.includes(listingIndex) ? 'line/ic-chevron/down' : 'line/ic-chevron/right' }}"
                  (click)="toggleRowExpand($event, listingIndex)"
                ></pup-icon>
              </ng-container>
            </pup-table-cell>
            <pup-table-cell [class.blank]="errorIndex > 0" class="strong">
              <ng-container *ngIf="errorIndex < 1">{{ listing.sku }}</ng-container>
            </pup-table-cell>
            <pup-table-cell [class.blank]="errorIndex > 0">
              <ng-container *ngIf="errorIndex < 1">
                <pup-image class="listing-image" [placeholderVariant]="ImagePlaceholderVariant.Small" [src]="listing.image">
                </pup-image>
              </ng-container>
            </pup-table-cell>
            <pup-table-cell [class.blank]="errorIndex > 0">
              <ng-container *ngIf="errorIndex < 1">{{ listing.name }}</ng-container>
            </pup-table-cell>
            <pup-table-cell>
              <span>{{ error.code }}</span>
              <span class="extra-error-count" *ngIf="errorIndex === 0 && listing.errors.length > 1">(+{{listing.errors.length - 1}})</span>
            </pup-table-cell>
            <pup-table-cell>{{ error.message }}</pup-table-cell>
            <pup-table-cell>{{ error.submissionId }}</pup-table-cell>
            <pup-table-cell>{{ error.submissionTimestamp | dateTime }}</pup-table-cell>
          </pup-table-row>
        </ng-container>
      </ng-container>
  </ng-container>

  <ng-container *ngIf="!queryResults">
    <app-empty-placeholder
      class="empty-placeholder"
      [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
      [emptyPlaceholderMessage]="'marketplaces.errors.no-results.message'"
      [emptyPlaceholderDescription]="'marketplaces.errors.no-results.description'"
    >
    </app-empty-placeholder>
  </ng-container>
</pup-table>

<ng-template #empty>
  <app-empty-placeholder
    *ngIf="queryResults"
    class="empty-placeholder"
    [emptyPlaceholderVariant]="placeholderVariant"
    [emptyPlaceholderImgSrc]="placeholderImgSrc"
    [emptyPlaceholderMessage]="noProductsMessageKey"
    [emptyPlaceholderDescription]="noProductsDescriptionKey"
  >
  </app-empty-placeholder>
</ng-template>

<app-skeleton-table *ngIf="loading"></app-skeleton-table>
