import { Component, Input } from '@angular/core';
import { JuniperComponent } from 'lib-juniper';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss']
})
export class InfoBar extends JuniperComponent {

  @Input()
  mainMessage: string = '';

  @Input()
  extraMessage?: string;
}
