import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { JuniperModule } from 'lib-juniper';

import { ListingsPage } from './listings/listings.page';
import { SharedModule } from '@app/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarketplaceComponent } from '@app/pages/marketplace/marketplace.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MarketplaceErrorsPage } from '@app/pages/marketplace/errors/marketplace-errors.page';
import { MarketplaceDashboardPage } from '@app/pages/marketplace/dashboard/marketplace-dashboard.page';
import { MarketplaceOrdersPage } from '@app/pages/marketplace/orders/marketplace-orders.page';
import { ListingDetailsPage } from '@app/pages/marketplace/listing-details/listing-details.page';
import { MarketplaceErrorDetailsPage } from '@app/pages/marketplace/error-details/marketplace-error-details.page';
import { ErrorsTableComponent } from '@app/components/tables/errors-table/errors-table.component';
import { ListingErrorsComponent } from '@app/components/tables/listing-errors/listing-errors.component';
import { MarketplaceSettingsPage } from '@app/pages/marketplace/settings/marketplace-settings.page';
import { MarketplaceSidebarComponent } from '@app/components/marketplaces/sidebar/marketplace-sidebar.component';
import { CategoryFilterComponent } from '@app/components/marketplaces/category-filter/category-filter.component';
import { ProductStatusFilter } from '@app/components/products/status-filter/status-filter.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    JuniperModule,
    SharedModule,
    MatSidenavModule,
  ],
  exports: [],
  declarations: [
    CategoryFilterComponent,
    ErrorsTableComponent,
    ListingErrorsComponent,
    ProductStatusFilter,
    MarketplaceComponent,
    MarketplaceSidebarComponent,
    MarketplaceErrorsPage,
    MarketplaceErrorDetailsPage,
    MarketplaceDashboardPage,
    MarketplaceOrdersPage,
    ListingsPage,
    ListingDetailsPage,
    MarketplaceSettingsPage,
  ]
})
export class MarketplaceModule { }
