import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant, JuniperComponent, PlaceholderVariant } from 'lib-juniper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, MIN_PAGE_SIZE } from '@app/interfaces';

@Component({
  selector: 'app-table-controls',
  templateUrl: './table-controls.component.html',
  styleUrls: ['./table-controls.component.scss'],
})
export class TableControlsComponent extends JuniperComponent {

  @Input()
  disabled: boolean = false;

  @Input()
  allToggled: boolean = false;

  @Input()
  showSelectBox: boolean = false;

  @Input()
  showSelectAll: boolean = false;

  @Input()
  selectedCount: number = 0;

  @Input()
  total: number = 0;

  @Input()
  page: number = DEFAULT_CURRENT_PAGE;

  @Input()
  perPage: number = DEFAULT_PAGE_SIZE;

  @Input()
  searchResults: boolean = false;

  @Input()
  selectLabel: string = 'marketplaces.products.selected';

  @Input()
  hideSelect: boolean = false;

  @Output()
  pageChange = new EventEmitter<number>();

  @Output()
  perPageChange = new EventEmitter<number>();

  @Output()
  toggleAll = new EventEmitter<void>();

  @Output()
  selectAll = new EventEmitter<void>();

  PlaceholderVariant = PlaceholderVariant;
  ButtonVariant = ButtonVariant;
  MIN_PAGE_SIZE = MIN_PAGE_SIZE;
}
