import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ToastService, PlaceholderVariant } from 'lib-juniper';

import { MarketplacesService } from '@app/services/marketplaces.service';
import { Marketplace } from '@app/interfaces/marketplace';
import { Page } from '@app/pages/page';
import { TranslateService } from '@ngx-translate/core';
import { getCountryTranslateKey } from '@app/shared/utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-marketplaces-page',
  templateUrl: './marketplaces.page.html',
  styleUrls: ['../page.scss', './marketplaces.page.scss'],
})
export class MarketplacesPage extends Page implements OnInit {

  regions: string[] = [];
  regionMarketplaces: Record<string, Marketplace[]> = {};
  filteredRegionMarketplaces: Record<string, Marketplace[]> = {};
  filter: string = '';
  filteredRegions: string[] = [];
  PlaceholderVariant = PlaceholderVariant;

  constructor(
    public router: Router,
    public marketplacesService: MarketplacesService,
    public modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private toastService: ToastService,
    ref: ElementRef,
    public activatedRoute: ActivatedRoute,
  ) {
    super(ref, router, activatedRoute);
  }

  async ngOnInit() {
    const marketplaces: Marketplace[] = await this.marketplacesService.fetchActiveMarketplaces().toPromise();
    const { regions, marketplacesByRegion } = this.marketplacesService.splitToRegions(marketplaces);

    this.regions = regions;
    this.regionMarketplaces = marketplacesByRegion;
    this.filteredRegionMarketplaces = { ...this.regionMarketplaces };
    this.filteredRegions = [...this.regions];
    this.loading = false;
    this.cdr.detectChanges();
  }

  handleAddMarketPlaceClick() {
    this.router.navigate(['add-marketplace']);
  }

  handleSettingsClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleMarketplaceSelect(marketplaceId: number) {
    this.router.navigate([`marketplaces/${marketplaceId}/listings`]);
  }

  onFilterUpdate(filter: string) {
    this.filter = filter;
    if (filter && filter.length) {
      this.filteredRegionMarketplaces = {};
      this.filteredRegions = [];

      Object.keys(this.regionMarketplaces).forEach(region => {
        const filteredMarketplaces = this.regionMarketplaces[region].filter(({ country }) => {
          const countryName = this.translateService.instant(getCountryTranslateKey(country.code));
          return countryName.toLowerCase().includes(this.filter.toLowerCase());
        });
        if (filteredMarketplaces.length) {
          this.filteredRegions.push(region);
          this.filteredRegionMarketplaces[region] = filteredMarketplaces;
        }
      });
    } else {
      this.filteredRegionMarketplaces = { ...this.regionMarketplaces };
      this.filteredRegions = [...this.regions];
    }
    this.cdr.detectChanges();
  }

}

