<app-table-controls
  [allToggled]="allSelected || marked.length > 0"
  [disabled]="!items.length || loading"
  [hideSelect]="true"
  [showSelectBox]="allSelected || marked.length > 0"
  [showSelectAll]="isSelectAllVisible"
  [selectedCount]="allSelected ? (total - marked.length) : marked.length"
  [selectLabel]="selectedLabel"
  [total]="total"
  [(page)]="page"
  [(perPage)]="perPage"
  (selectAll)="selectAll()"
  (toggleAll)="toggleAllOnPage()"
>
  <ng-content select=".actions"></ng-content>
</app-table-controls>
<pup-table *ngIf="!loading">
  <pup-table-head>
    <pup-table-cell></pup-table-cell>
    <pup-table-cell
      [sortable]="true"
      (sortClick)="setColumnSorting($event, 'code')"
      [ascending]="sorting?.code?.ascending"
      [descending]="sorting?.code?.descending"
    >
      {{ 'marketplaces.errors.table.error-code' | translate }}
    </pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.errors.table.error-description' | translate }}</pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.errors.table.affected-products' | translate }}</pup-table-cell>
    <pup-table-cell>{{ 'marketplaces.errors.table.submission-id' | translate }}</pup-table-cell>
    <pup-table-cell
      [sortable]="true"
      (sortClick)="setColumnSorting($event, 'submissionTimestamp')"
      [ascending]="sorting?.submissionTimestamp?.ascending"
      [descending]="sorting?.submissionTimestamp?.descending"
    >
      {{ 'marketplaces.errors.table.submission-timestamp' | translate }}
    </pup-table-cell>
  </pup-table-head>

  <ng-container *ngIf="items.length > 0 && queryResults; else empty">
    <ng-container *ngFor="let group of items; let groupIndex = index">
      <ng-container *ngFor="let error of group.errors; let detailIndex = index">
        <pup-table-row
          *ngIf="detailIndex < 1 || expandedRows.includes(groupIndex)"
          (select)="handleSelectError(group.code)"
        >
          <pup-table-cell [class.blank]="detailIndex > 0">
            <ng-container *ngIf="detailIndex === 0">
              <pup-icon
                *ngIf="detailIndex === 0 && group.errors.length > 1"
                path="{{ expandedRows.includes(groupIndex) ? 'line/ic-chevron/down' : 'line/ic-chevron/right' }}"
                (click)="toggleRowExpand($event, groupIndex)"
              ></pup-icon>
            </ng-container>
          </pup-table-cell>
          <pup-table-cell class="strong" [class.blank]="detailIndex > 0">
            <ng-container *ngIf="detailIndex === 0">{{ group.code }}</ng-container>
          </pup-table-cell>
          <pup-table-cell>
            {{ error.message }}
            <span class="extra-error-count" *ngIf="detailIndex === 0 && group.errors.length > 1">
                (+{{group.errors.length - 1}})
              </span>
          </pup-table-cell>
          <pup-table-cell>
            <ng-container *ngIf="error.affectedListings">{{ error.affectedListings.length }}</ng-container>
          </pup-table-cell>
          <pup-table-cell [class.blank]="detailIndex > 0">
            <ng-container *ngIf="detailIndex === 0">{{ group.submissionId }}</ng-container>
          </pup-table-cell>
          <pup-table-cell [class.blank]="detailIndex > 0">
            <ng-container *ngIf="detailIndex === 0">{{ group.submissionTimestamp | dateTime }}</ng-container>
          </pup-table-cell>
        </pup-table-row>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!queryResults">
    <app-empty-placeholder
      class="empty-placeholder"
      [emptyPlaceholderVariant]="PlaceholderVariant.NotFoundV2"
      [emptyPlaceholderMessage]="'marketplaces.errors.no-results.message'"
      [emptyPlaceholderDescription]="'marketplaces.errors.no-results.description'"
    >
    </app-empty-placeholder>
  </ng-container>

</pup-table>

<ng-template #empty>
    <app-empty-placeholder
      *ngIf="queryResults"
      class="empty-placeholder"
      [emptyPlaceholderVariant]="placeholderVariant"
      [emptyPlaceholderImgSrc]="placeholderImgSrc"
      [emptyPlaceholderMessage]="noProductsMessageKey"
      [emptyPlaceholderDescription]="noProductsDescriptionKey"
    >
    </app-empty-placeholder>
</ng-template>


<app-skeleton-table *ngIf="loading"></app-skeleton-table>
