import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { Marketplace } from '@app/interfaces';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-marketplace-settings-page',
  templateUrl: './marketplace-settings.page.html',
  styleUrls: ['../../page.scss', './marketplace-settings.page.scss'],
})
export class MarketplaceSettingsPage extends MarketplacePage implements OnInit {

  marketplace!: Marketplace;

  constructor(
    ref: ElementRef,
    public router: Router,
    public marketplacesService: MarketplacesService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(ref, router, marketplacesService, activatedRoute);
  }

  ngOnInit(): void {
    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe((marketplace) => {
      if (marketplace) {
        this.marketplace = marketplace;
      }
    });
  }

  updateMarketplace(marketplace: Marketplace) {
    this.marketplace = marketplace;
    this.marketplacesService.updateMarketplace(marketplace).subscribe();
  }
}
