<pup-content>
  <ng-container *ngIf="product; else spinner">
    <pup-breadcrumbs home="/" [navigate]="navigate">
      <pup-breadcrumb *ngFor="let breadcrumb of breadcrumbs" [url]="breadcrumb.url">
        <span [tooltip]="breadcrumb.name | translate">{{ breadcrumb.name | translate }}</span>
      </pup-breadcrumb>
    </pup-breadcrumbs>

    <pup-heading [level]="2">
      <div>
        <span class="sku">{{ product.sku }}</span> - {{ product.title }}
      </div>
      <div class="header-controls">
        <app-listing-status [status]="product.status"></app-listing-status>
        <div class="vertical-divider"></div>
        <app-readiness-indicator [ready]="ready"></app-readiness-indicator>
        <div class="vertical-divider"></div>
        <div class="controls-container" >
          <pup-button [variant]="ButtonVariant.Secondary" (click)="saveListing()">
            {{ 'marketplaces.product-listing-details-page.save' | translate }}
          </pup-button>
          <ng-container *ngIf="listingValidationService.currentErrorCount$ | async as errorCount; else button">
            <pup-button
              [disabled]="true"
              [tooltip]="'marketplaces.marketplace.listing-details.submit.disabled-tooltip' | translate"
            >
              {{ 'marketplaces.marketplace.products.list' | translate }}
            </pup-button>
          </ng-container>
          <ng-template #button>
            <pup-button (click)="listItem()">
              {{ 'marketplaces.marketplace.products.list' | translate }}
            </pup-button>
          </ng-template>
        </div>
      </div>
    </pup-heading>

    <div class="main-container">
      <div class="side-container">
        <div class="side-menu">
          <pup-menu class="menu" *ngIf="initDone">
            <ng-container *ngIf="listingValidationService.errorCountByGroup$ | async as groupErrorCounts">
            <pup-menu-item
              (select)="scrollToTop()"
              [selected]="selectedMenuIndex === 0"
            >
              {{ 'marketplaces.marketplace.listing-details.menu-item.main' | translate }}
              <pup-badge
                *ngIf="groupErrorCounts.main"
                [tooltip]="'marketplaces.listings.details.errors.missing-required-attributes' | translate: { count: groupErrorCounts.main }"
                [tooltipPlacement]="TooltipPlacement.Right"
              >{{ groupErrorCounts.main }}</pup-badge>
            </pup-menu-item>
            <pup-menu-item
              *ngFor="let menu of menuItems; let index = index"
              (select)="scrollToIndex(index)"
              [selected]="selectedMenuIndex === index + 1"
            >
              {{ menu.label }}
              <pup-badge
                *ngIf="groupErrorCounts[menu.key]"
                [tooltip]="'marketplaces.listings.details.errors.missing-required-attributes' | translate: { count: groupErrorCounts[menu.key] }"
                [tooltipPlacement]="TooltipPlacement.Right"
              >
                {{ groupErrorCounts[menu.key] }}
              </pup-badge>
            </pup-menu-item>
            </ng-container>
          </pup-menu>
          <ng-container *ngIf="requiredCount">
            <pup-floating-label>{{ 'marketplaces.marketplace.listing-details.filter' | translate }}</pup-floating-label>
            <pup-select [variant]="SelectVariant.Multiple">
              <pup-select-item [selected]="onlyRequired" (select)="toggleRequired()"
              >
                {{ "marketplaces.marketplace-product-details.required" | translate: { count: requiredCount } }}
              </pup-select-item>
              <pup-select-item [selected]="onlyOptional" (select)="toggleOptional()"
              >
                {{ "marketplaces.marketplace-product-details.optional" | translate: { count: inputsArray.length - requiredCount } }}
              </pup-select-item>
            </pup-select>
          </ng-container>
        </div>
      </div>
      <div class="form-container" #formContainer (scroll)="onFormScroll($event)" *ngIf="form">
        <!-- ng-container *ngIf="validationErrors?.length">
          <app-error-notification
            *ngFor="let error of validationErrors"
            [message]="error.message | translate"
          ></app-error-notification>
        </ng-container -->
        <app-dynamic-form
          [form]="form"
          [inputs]="inputsArray"
          [onlyOptional]="onlyOptional"
          [onlyRequired]="onlyRequired"
          (formChange)="onFormChange()"
        ></app-dynamic-form>
      </div>
    </div>
  </ng-container>

  <ng-template #spinner>
    <pup-loading-visual></pup-loading-visual>
  </ng-template>
</pup-content>
