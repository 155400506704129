import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant, DropdownDirection, ImagePlaceholderVariant, PlaceholderVariant } from 'lib-juniper';

import { Attribute, DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, MIN_PAGE_SIZE } from '@app/interfaces';
import { TableBaseComponent } from '@app/components/tables/table-base.component';
import { Product, ProductKey } from '@app/interfaces';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TableSortingState } from '@app/services/ui-state.service';

// const DEFAULT_ATTRIBUTES: ProductKey[] = ['image', 'title', 'sku', 'lastUpdate', 'category'];
const DEFAULT_ATTRIBUTES: Attribute[] = [
  { name: 'Category', value: 'category', isSystem: true },
  { name: 'Last update', value: 'lastUpdate', isSystem: true },
  { name: 'Marketplaces', value: 'marketplaces', isSystem: true }
];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['../table.scss', './product-table.component.scss'],
})
export class ProductTableComponent extends TableBaseComponent<Product> {

  @Input()
  displayedAttributes: Attribute[] = DEFAULT_ATTRIBUTES;

  @Input()
  noResultsFoundMessage = 'marketplaces.listings.no-results.message';

  @Output()
  attributesReorder = new EventEmitter<{ currentIndex: number, previousIndex: number }>();

  @Input()
  lockedAttributes: Attribute[] = [];

  MIN_PAGE_SIZE = MIN_PAGE_SIZE;
  PlaceholderVariant = PlaceholderVariant;
  ImagePlaceholderVariant = ImagePlaceholderVariant;
  ButtonVariant = ButtonVariant;
  DropdownDirection = DropdownDirection;

  drop(event: CdkDragDrop<string[]>) {
    const { currentIndex, previousIndex } = event;
    this.attributesReorder.emit({ currentIndex, previousIndex });
  }

  attributeIsNotLocked(attribute: Attribute) {
    return !this.lockedAttributes.find(item => item.value === attribute.value);
  }
}
