<pup-button [variant]="ButtonVariant.Tool" *ngIf="!editMode" (click)="toggleEditMode()">{{ value }}</pup-button>
<ng-container *ngIf="editMode">
  <pup-numeric-input [(value)]="value"></pup-numeric-input>
  <pup-button class="control-button" [variant]="ButtonVariant.Icon" (click)="save()">
    <pup-icon path="line/ic-check"></pup-icon>
  </pup-button>
  <pup-button class="control-button" [variant]="ButtonVariant.Icon" (click)="cancel()">
    <pup-icon path="line/ic-close"></pup-icon>
  </pup-button>
</ng-container>
