import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '@app/pages/page';
import { Pagination, Product } from '@app/interfaces';
import { CategoryService } from '@app/services/category.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { ModalService } from 'lib-juniper';
import { LocalStorageService } from '@app/services/local-storage.service';

const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;

export abstract class PageWithProducts extends Page {

  products: Product[] = [];

  pagination: Pagination = {
    currentPage: DEFAULT_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
  };

  selectedProductIds: string[] = [];
  allProductsSelected: boolean = false;

  searchResults: boolean = false;
  query: string = '';

  constructor(
    ref: ElementRef,
    public router: Router,
    public categoryService: CategoryService,
    public modalService: ModalService,
    public cdr: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
  ) {
    super(ref, router, activatedRoute);
    this.restoreProductSelectionsIfAny();
  }

  handlePaginationChange(pagination: Partial<Pagination>) {
    this.pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;
    this.pagination.currentPage = pagination.currentPage || DEFAULT_CURRENT_PAGE;

    this.loadProducts();
  }

  showCategorySelectionModal() {
    this.saveProductSelectionsIfNeeded(); // TODO refactor full screen modals as modals so this is not needed at all
    this.router.navigate(['select-category']);
  }

  showAttributeSelectionModal(category?: string) {
    const route = ['select-attributes'];
    if (category) {
      route.push(category);
    }
    this.saveProductSelectionsIfNeeded(); // TODO refactor full screen modals as modals so this is not needed at all
    this.router.navigate(route);
  }

  reorderAttributes({ currentIndex, previousIndex }: { currentIndex: number, previousIndex: number }, isListing?: boolean) {
    const displayedAttributes = isListing ? this.categoryService.displayedListingAttributes : this.categoryService.displayedProductAttributes;
    moveItemInArray(displayedAttributes, previousIndex, currentIndex);
    this.cdr.detectChanges();
  }

  abstract loadProducts(): void;

  resetPaginationAndLoad() {
    this.pagination.currentPage = DEFAULT_CURRENT_PAGE;
    this.loadProducts();
  }


  saveProductSelectionsIfNeeded() {
    if (!this.selectedProductIds.length && !this.allProductsSelected) {
      return;
    }
    this.localStorageService.saveProductSelections({
      selections: this.selectedProductIds,
      all: this.allProductsSelected,
      url: window.location.href,
    });
  }

  restoreProductSelectionsIfAny() {
    try {
      const json = this.localStorageService.getProductSelections();
      if (!json || json.url !== window.location.href) { // make sure we're on the same page
        return;
      }
      this.allProductsSelected = json.all;
      this.selectedProductIds = json.selections;
    } catch(e){
    } finally {
      this.localStorageService.clearProductSelections();
    }
  }

}
