<pup-wizard
  class="add-marketplace-wizard"
  [validate]="validate"
  [errorMessage]="errorMessage"
  [validationHint]="validationHint"
  [loading]="loading"
  (close)="close()"
  (finish)="finish()"
  finishLabel="marketplaces.add-marketplace.finish"
  [(page)]="page"
  caption="{{ 'marketplaces.add-marketplace.caption.main' | translate }}"
>

  <ng-template wizard-page caption="{{ 'marketplaces.add-marketplace.caption.select-marketplace-type' | translate }}">
    <pup-list class="marketplace--selection">
      <pup-simple-list-item
      *ngFor="let mType of marketplaceTypes"
      [selected]="mType.type === selectedMarketplaceType"
      (select)="checkSelection(mType.type)"
      >
        <div class="marketplace--type-container">
          <div class="marketplace--type-visuals">
            <div class="marketplace--provider-logo">
              <img src="assets/visuals/merchants/{{mType.icon}}" alt="{{mType.name}} logo">
            </div>
          </div>
          <div class="marketplace--type-name">
            <span>{{mType.name}}</span>
          </div>
        </div>
      </pup-simple-list-item>
    </pup-list>
  </ng-template>

  <ng-template wizard-page caption="{{ 'marketplaces.add-marketplace.caption.region-select' | translate }}">
    <pup-paragraph class="marketplace--wrapper">
      <pup-list class="marketplace-list">
        <div class="regions-container">
          <div *ngFor="let region of filteredRegions">
            <pup-heading [level]="4" class="region">
              <span>{{ region }} {{ 'marketplaces.add-marketplace.region' | translate }}</span>
            </pup-heading>

            <ng-container *ngFor="let marketplace of regionMarketplaces[region];">
              <pup-toggle-list-item
                *ngIf="marketplace.seller === (selectedMarketplaceType === 'seller')"
                (select)="selectedMarketplace = marketplace"
                [toggled]="selectedMarketplace === marketplace"
                [selected]="selectedMarketplace === marketplace"
                [disabled]="marketplace.active"
                [tooltip]="marketplace.active ? ('marketplaces.add-marketplace.notice.already-added' | translate) : ''"
                [tooltipPlacement]="TooltipPlacement.Right"
              >
                <app-country-tag [marketplace]="marketplace" [disabled]="marketplace.active"></app-country-tag>
              </pup-toggle-list-item>

              <app-info-bar
                *ngIf="selectedMarketplace && selectedMarketplace === marketplace"
                [mainMessage]="selectedMarketplaceType === 'seller' ? ('marketplaces.add-marketplace.notice.amazon-auth-main' | translate) : ('marketplaces.add-marketplace.notice.amazon-auth-main-vendor' | translate)"
                [extraMessage]="'marketplaces.add-marketplace.notice.amazon-auth-extra' | translate"
              ></app-info-bar>
            </ng-container>
          </div>
        </div>
      </pup-list>
      <div class="marketplace--info-section" *ngFor="let marketplaceType of marketplaceTypes">
        <div class="marketplace--info-section--type-container" *ngIf="marketplaceType.type === selectedMarketplaceType">
          <pup-heading [level]="5" class="pb-7">
            <span>{{ 'marketplaces.add-marketplace.heading.marketplace-type' | translate }}</span>
          </pup-heading>

          <div class="marketplace--info-section--type-visuals pb-3">
            <div class="marketplace--info-section--provider-logo">
              <img src="assets/visuals/merchants/{{marketplaceType.icon}}" alt="{{marketplaceType.name}} logo">
            </div>
          </div>

          <div class="marketplace--info-section--type-name">
            <span>{{marketplaceType.name}}</span>
          </div>
        </div>
      </div>
    </pup-paragraph>


  </ng-template>

  <ng-template wizard-page caption="{{ 'marketplaces.add-marketplace.caption.setup' | translate }}">
    <app-marketplace-tag [marketplace]="selectedMarketplace" *ngIf="selectedMarketplace"></app-marketplace-tag>
    <app-marketplace-settings [(marketplace)]="selectedMarketplace"></app-marketplace-settings>
  </ng-template>

</pup-wizard>
