import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { HeadingComponent, JuniperComponent } from 'lib-juniper';
import { GroupInput } from '@app/components/dynamic-form/inputs/input-group';
import { ListingValidationService } from '@app/services/listing-validation.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { InputControlType } from '@app/interfaces';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent extends JuniperComponent implements OnChanges, OnInit {

  @ViewChildren(HeadingComponent, { read: ElementRef })
  headings?: QueryList<ElementRef>;

  _inputs: InputBase[] = [];

  images: InputBase[] = [];
  imageGroups: InputBase[][] = [];
  nonImages: InputBase[] = [];

  @Input()
  onlyOptional: boolean = false;

  @Input()
  onlyRequired: boolean = false;

  @Input()
  readonly form!: FormGroup;

  @Input()
  noSave: boolean = false;

  @Input('inputs')
  set setInputs(inputs: InputBase[]) {
    this._inputs = inputs;

    this.filterAndParseInputs();
  };

  @Output()
  formChange: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    ref: ElementRef,
    public listingValidationService: ListingValidationService
  ) {
    super(ref);
  }

  get nonGroupInputs() {
    return this.nonImages.filter(input => !(input instanceof GroupInput));
  }

  get groupInputs() {
    return this.nonImages.filter(input => input instanceof GroupInput);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.onlyRequired || changes.onlyOptional) {
      this.filterAndParseInputs();
    }
  }

  ngOnInit() {
    this.recalculateErrors();
    this.form.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.recalculateErrors();
      this.formChange.emit();
    }, (error) => {
      console.log('ERROR: ', error);
    });
  }

  getHeadingTopsMap(): number[] {
    if (!this.headings) {
      return [];
    }
    return this.headings.map(heading => heading?.nativeElement?.offsetTop || 0);
  }

  private filterAndParseInputs() {
    this.nonImages = this._inputs.filter(input => input.controlType !== 'image' && this.isShown(input));
    const shownImages = this._inputs.filter(input => input.controlType === 'image' && this.isShown(input));
    return this.parseImageGroups(shownImages);
  }

  private isShown(input: InputBase) {
    return (this.onlyRequired && input.required) || (this.onlyOptional && !input.required) || (!this.onlyOptional && !this.onlyRequired);
  }

  private parseImageGroups(inputs: InputBase[]) {
    this.images = [];
    const groups: { [key: string]: InputBase[] } = {};
    const imageInputs = inputs.filter((input => input.controlType === InputControlType.Image));
    imageInputs.forEach(input => {
      if (input.key.endsWith('_1')) {
        groups[(input.key.slice(0, -2))] = [input];
        return;
      }
    });
    imageInputs.forEach(input => {
      for (let name of Object.keys(groups)) {
        if (input.key.includes(name)) {
          groups[name].push(input);
          return;
        }
      }
      this.images.push(input);
    });
    this.images.sort((input) => input.key === 'main_product_image_locator' ? -1 : 1); // main image goes first
    this.imageGroups = Object.values(groups);
  }

  getFormValidationErrorCounts(group: FormGroup, top: boolean = true): { total: number, byGroup: { [key: string]: number } } {
    let total: number = 0;
    const byGroup: { [key: string]: number } = { main: 0 };
    Object.keys(group.controls).forEach(key => {
      const element: AbstractControl | null = group.get(key);
      if (element instanceof FormGroup) {
        const group: FormGroup = element as FormGroup;
        const result = this.getFormValidationErrorCounts(group, false);
        total += result.total;
        if (!byGroup[key]) {
          byGroup[key] = 0;
        }
        byGroup[key] += result.total;
      } else {
        const controlErrors: ValidationErrors | null = element!.errors;
        if (controlErrors != null) {
          const errorLength = Object.keys(controlErrors).length;
          total += errorLength;
          byGroup.main += errorLength;
        }
      }
    });
    return {
      total,
      byGroup,
    };
  }

  recalculateErrors() {
    const { total, byGroup } = this.getFormValidationErrorCounts(this.form);

    this.listingValidationService.setErrorCount(total);
    this.listingValidationService.setErrorCountByMenu(byGroup);
  }
}
