import { Injectable } from '@angular/core';
import { Pagination, Product } from '@app/interfaces';
import { Observable } from 'rxjs';

import { ApiClient } from 'lib-platform-common';
import { ExtendedParams } from 'lib-platform-common/interfaces';
import { TableSortingState } from '@app/services/ui-state.service';
import { extendParamsWithSorting } from '@app/shared/utils';

export interface PaginatedResponse<T> {
  data: T[];
  meta: Pagination;
}

export interface ProductOptions {
  pageSize: number;
  currentPage: number;
  categories?: string[];
  query?: string;
  sorting?: TableSortingState,
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  constructor(private api: ApiClient) {
  }

  getProducts(options: ProductOptions): Observable<PaginatedResponse<Product>> {
    const { currentPage, query, pageSize, sorting } = options;

    const params: ExtendedParams = { currentPage, pageSize };
    if (query && query.length) {
      params.query = query;
    }

    extendParamsWithSorting(params, sorting);

    return this.api.get<PaginatedResponse<Product>>({ endpoint: 'products', params });
  }

  getProductsByCategory(options: ProductOptions): Observable<PaginatedResponse<Product>> {
    const { categories, currentPage, query, pageSize, sorting } = options;
    const data: { categories?: string[], query?: string } = { categories };

    if (query && query.length) {
      data.query = query;
    }
    const params: ExtendedParams = { currentPage, pageSize };
    extendParamsWithSorting(params, sorting);

    return this.api.post<PaginatedResponse<Product>>({
      data,
      params,
      endpoint: 'products',
    });
  }

  getSingleProduct(productId: string): Observable<Product> {
    return this.api.get<Product>({ endpoint: `products/${productId}` });
  }

  getSingleProductJson(productId: string): Observable<any> { // TODO needs a type
    return this.api.get<Product>({ endpoint: `products/${productId}`, params: { format: 'json' } });
  }

  saveProduct(productId: string, product: Product) {
    return this.api.post({ endpoint: `products/${productId}/attributes`, data: product });
  }
}
