import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { JuniperModule } from 'lib-juniper';
import { HeaderComponent } from '@app/components/header/header.component';
import { RouterModule } from '@angular/router';
import { MarketplaceTagComponent } from '@app/components/marketplaces/marketplace-tag/marketplace-tag.component';
import { DynamicFormComponent } from '@app/components/dynamic-form/dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormInputComponent } from '@app/components/dynamic-form/dynamic-form-input.component';
import { ColumnTitleComponent } from '@app/components/products/column-title/column-title.component';
import { InfoBar } from '@app/components/info-bar/info-bar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SkeletonTableComponent } from '@app/components/tables/skeleton/skeleton-table.component';
import { TableControlsComponent } from '@app/components/tables/table-controls/table-controls.component';
import { AmazonAuthFailedComponent } from '@app/components/authentication/amazon/amazon-auth-failed.component';
import { AmazonAuthSuccessComponent } from '@app/components/authentication/amazon/amazon-auth-success.component';
import { RegionTagComponent } from '@app/components/marketplaces/region-tag/region-tag.component';
import { ProductTableComponent } from '@app/components/tables/product-table/product-table.component';
import { MarketplaceFlagsComponent } from '@app/components/marketplaces/marketplace-flags/marketplace-flags.component';
import { MarketplaceSettingsComponent } from '@app/components/marketplaces/settings/marketplace-settings.component';
import { FilteredSelectedListComponent } from '@app/components/list-select/selected-attributes/filtered-selected-list.component';
import { CountryTagComponent } from '@app/components/marketplaces/country-tag/country-tag.component';
import { MarketplaceDomainTagComponent } from '@app/components/marketplaces/marketplace-domain-tag/marketplace-domain-tag.component';
import { MarketplaceLogoFlagComponent } from '@app/components/marketplaces/marketplace-logo-flag/marketplace-logo-flag.component';
import { FilterSwitcherComponent } from '@app/components/filter-switcher/filter-switcher.component';
import {
  ReadinessIndicatorComponent
} from '@app/components/marketplaces/readiness-indicator/readiness-indicator.component';
import { DynamicDropdownInputComponent } from '@app/components/dynamic-form/view/dynamic-dropdown-input.component';
import { ImageGroupComponent } from '@app/components/dynamic-form/view/image-group/image-group.component';
import { ErrorNotificationComponent } from '@app/components/error/error-notification/error-notification.component';
import { ListingStatusComponent } from '@app/components/listing-status/listing-status.component';
import {
  DynamicDateInputComponent
} from '@app/components/dynamic-form/view/dynamic-date-input/dynamic-date-input.component';
import { ButtonOrInputComponent } from '@app/components/button-or-input/button-or-input.component';
import {
  DynamicToggleInputComponent
} from '@app/components/dynamic-form/view/dynamic-toggle-input/dynamic-toggle-input.component';
import { EmptyPlaceholderComponent } from '@app/components/empty-placeholder/empty-placeholder.component';


const EXPORTS = [
  AmazonAuthFailedComponent,
  AmazonAuthSuccessComponent,
  ButtonOrInputComponent,
  ColumnTitleComponent,
  DynamicDateInputComponent,
  DynamicDropdownInputComponent,
  DynamicFormComponent,
  DynamicFormInputComponent,
  DynamicToggleInputComponent,
  ErrorNotificationComponent,
  InfoBar,
  HeaderComponent,
  FilteredSelectedListComponent,
  ImageGroupComponent,
  ListingStatusComponent,
  MarketplaceDomainTagComponent,
  MarketplaceTagComponent,
  MarketplaceLogoFlagComponent,
  CountryTagComponent,
  MarketplaceSettingsComponent,
  MarketplaceFlagsComponent,
  ProductTableComponent,
  ReadinessIndicatorComponent,
  RegionTagComponent,
  SkeletonTableComponent,
  FilterSwitcherComponent,
  TableControlsComponent,
  EmptyPlaceholderComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    JuniperModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  exports: EXPORTS,
  declarations: EXPORTS
})
export class SharedModule {
}
