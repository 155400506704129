<pup-floating-label [optional]="!input.required">{{ input.label | translate }}</pup-floating-label>
<pup-dropdown-select (click)="touch()" [invalid]="invalid" [errorMessage]="hint">
  <pup-paragraph label>{{ valueLabel | translate }}</pup-paragraph>
  <pup-dropdown-menu-item (select)="select(true)">
    <span>{{ 'marketplaces.form.toggle.yes' | translate }}</span>
  </pup-dropdown-menu-item>
  <pup-dropdown-menu-item (select)="select(false)">
    <span>{{ 'marketplaces.form.toggle.no' | translate }}</span>
  </pup-dropdown-menu-item>
  <pup-dropdown-menu-item (select)="select()">
    <span>{{ 'marketplaces.form.toggle.undefined' | translate }}</span>
  </pup-dropdown-menu-item>
</pup-dropdown-select>
