import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { JuniperWithValueAccessorComponent, ToggleVariant } from 'lib-juniper';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';

@Component({
  selector: 'app-dynamic-toggle-input',
  styleUrls: ['./dynamic-toggle-input.component.scss', '../../inputs-common.scss'],
  templateUrl: './dynamic-toggle-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DynamicToggleInputComponent,
    }
  ]
})
export class DynamicToggleInputComponent extends JuniperWithValueAccessorComponent {

  value?: boolean;

  ToggleVariant = ToggleVariant;

  @Input()
  input!: InputBase;

  @Input()
  invalid: boolean = false;

  @Input()
  hint: string = '';

  get valueLabel(): string {
    if (typeof this.value === 'boolean') {
      return this.value ? 'marketplaces.form.toggle.yes' : 'marketplaces.form.toggle.no';
    }
    return 'marketplaces.form.toggle.undefined';
  }

  writeValue(value: any) {
    // FIXME initial value is parsed as string in formsService
    if (typeof value === 'boolean') {
      this.value = value;
      return;
    }
    if (typeof value === 'string') {
      this.value = value.toLowerCase() === 'true';
      return;
    }
    this.value = value;
  }

  setDisabledState(disabled: boolean) {
  }

  touch() {
    this.onTouched();
  }

  select(value?: boolean) {
    this.value = value;
    this.onChange(this.value);
  }
}
