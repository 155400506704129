<app-marketplace-sidebar
  [marketplace]="marketplace" *ngIf="marketplace$ | async as marketplace"
  parentRoute="../.."
>

  <ng-container *ngIf="errorDetails$ | async as errorDetailsByCode">
      <div class="header">
        <pup-breadcrumbs home="/" [navigate]="navigate">
          <pup-breadcrumb [url]="'../../..'">
            <span>{{ 'marketplaces.breadcrumbs.marketplaces' | translate }}</span>
          </pup-breadcrumb>
          <pup-breadcrumb [url]="'../..'">
            <span>{{ marketplace.description }} ({{ marketplace.country.code }})</span>
          </pup-breadcrumb>
          <pup-breadcrumb [url]="'..'">
            <span>{{ 'marketplaces.marketplace-errors.page-title' | translate }}</span>
          </pup-breadcrumb>
          <pup-breadcrumb>
            {{ 'marketplaces.marketplace-errors.error-details.error-code' | translate:{ code: errorId } }}
          </pup-breadcrumb>
        </pup-breadcrumbs>

        <pup-heading [level]="3">
          {{ 'marketplaces.marketplace-errors.error-details.error-code' | translate:{ code: errorId } }}
        </pup-heading>
      </div>
      <pup-content>
        <div class="error-wrapper" *ngFor="let errorDetails of errorDetailsByCode">
          <dl class="error-details">
            <dt>
              <pup-floating-label>{{ 'marketplaces.errors.table.error-description' | translate }}</pup-floating-label>
            </dt>
            <dd>{{ errorDetails.message }}</dd>
            <dt>
              <pup-floating-label>{{ 'marketplaces.marketplace-errors.error-details.submission-id' | translate }}</pup-floating-label>
            </dt>
            <dd>
              <div class="inline">
                <span>{{ errorDetails.submissionId }}</span>
                <pup-icon
                  class="clickable"
                  path="line/ic-copy"
                  size="18px"
                  (click)="copySubmissionIdClick(errorDetails.submissionId)"
                  [tooltip]="'marketplaces.marketplace-errors.error-details.copy-to-clipboard' | translate"
                  [tooltipPlacement]="TooltipPlacement.Right"
                ></pup-icon>
              </div>
            </dd>
            <dt>
              <pup-floating-label>{{ 'marketplaces.marketplace-errors.error-details.submission-timestamp' | translate }}</pup-floating-label>
            </dt>
            <dd>{{ errorDetails.submissionTimestamp | dateTime }}</dd>
            <dt>
              <pup-floating-label>{{ 'marketplaces.marketplace-errors.error-details.affected-products' | translate }}</pup-floating-label>
            </dt>
            <dd>
              <div class="inline" *ngFor="let product of errorDetails.affectedListings">
                <a [routerLink]="['../../listings', product.id]" target="_blank" class="listings-product-url">
                  {{ product.sku }}
                  <pup-icon path="line/ic-export" size="18px"></pup-icon>
                </a>
              </div>

            </dd>
          </dl>
        </div>
      </pup-content>

  </ng-container>
</app-marketplace-sidebar>
