import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { ImageInput } from '@app/components/dynamic-form/inputs/input-image';
import { TextInput } from '@app/components/dynamic-form/inputs/input-text';
import { GroupInput } from '@app/components/dynamic-form/inputs/input-group';
import { ToggleInput } from '@app/components/dynamic-form/inputs/input-toggle';
import { ChoiceInput } from '@app/components/dynamic-form/inputs/input-choice';
import { DateInput } from '@app/components/dynamic-form/inputs/input-date';
import { format, parse } from 'date-fns';
import { NumberInput } from '@app/components/dynamic-form/inputs/input-number';
import { get, set } from 'lodash';
import { InputControlType } from '@app/interfaces';
import { TextAreaInput } from '@app/components/dynamic-form/inputs/input-textarea';

const IGNORED_COLUMNS = ['marketplace_id', 'language_tag'];

@Injectable({
  providedIn: 'root',
})
export class FormsService {

  getFormAndInputs(product: unknown, schema: any): [FormGroup, { [key: string]: InputBase }] {
    let props: { [key: string]: unknown } = schema.properties;
    const requiredProps = schema.required;
    const [group, inputs] = this.parseForm(props, product, { requiredProps, top: true, pathToGroup: [] });
    const inputMap = inputs.reduce((acc, input) => Object.assign(acc, { [input.key]: input }), {});
    return [group, inputMap];
  }

  parseForm(
    formItems: { [key: string]: unknown },
    product: any,
    options: { requiredProps: string[], top: boolean, pathToGroup: (string | number)[] } = {
      requiredProps: [],
      top: true,
      pathToGroup: []
    }
  ): [FormGroup, InputBase[]] {

    const { requiredProps, top, pathToGroup } = options;
    const group: { [key: string]: AbstractControl } = {};
    const inputs: InputBase[] = [];
    if (!formItems) {
      return [new FormGroup(group), inputs];
    }

    Object.keys(formItems).forEach((key: string) => {
      if (IGNORED_COLUMNS.includes(key)) {
        return;
      }

      let property: any = formItems[key];
      const required: boolean = requiredProps.includes(key);

      let propKeys = [];
      if (property?.type === 'array') {
        propKeys = this.getNonIgnoredKeys(property?.items?.properties);
      } else {
        propKeys = this.getNonIgnoredKeys(property?.properties);
      }
      // TODO reduce if/else nesting
      if ((property?.type === 'array' && propKeys.length === 1 && property?.items?.properties[propKeys[0]]?.type !== 'array') || (property?.type !== 'array' && property?.type !== 'object')) {
        const value = this.getValue(product, key, property?.type, propKeys[0]);
        let pathInSchema = pathToGroup.concat([key, 0, propKeys[0]]);

        if (property?.type !== 'array') {
          pathInSchema = pathToGroup.concat([key]);
        } else if (property?.type === 'array' && property?.items?.required?.includes('media_location')) {
          pathInSchema = pathToGroup.concat([key, 0, 'media_location']);
        } else {
          property = property?.items?.properties[propKeys[0]];
        }
        group[key] = required ? new FormControl(value, Validators.required) : new FormControl(value);

        const options: any = {
          key,
          value,
          required,
          pathInSchema,
          label: property.title || key,
        };
        const input = this.parseSimpleType(property, options);
        if (input instanceof ImageInput) { // TODO this is a hack so main image is always first
          inputs.unshift(input);
        } else {
          inputs.push(input);
        }
        if (input instanceof NumberInput) {
          // custom number validator causes the whole form validation to randomly fail on load - commenting out for now
          // TODO remove this and comment above if the bug described above doesn't come back
          group[key].addValidators(Validators.pattern('^\\d*\\.?\\d*$'));
        }
      } else {

        if (property?.items?.properties || propKeys.length) {
          let propToParse = property?.items?.properties;
          let updatedPathToGroup = pathToGroup.concat([key, 0]);
          let subProduct = product[key]?.[0] || {};
          if (property?.type !== 'array') {
            propToParse = property.properties;
            updatedPathToGroup = pathToGroup.concat([key]);
            subProduct = product[key] || {};
          }
          const [groupForm, groupItems] = this.parseForm(
            propToParse,
            subProduct,
            { requiredProps, top: false, pathToGroup: updatedPathToGroup }
          );
          if (!groupItems.length) {
            return;
          }
          const groupInput = new GroupInput({
            key,
            label: property.title || key,
            required,
          });
          groupInput.topGroup = top;
          groupInput.groupItems = groupItems.reduce((acc, input) => Object.assign(acc, { [input.key]: input }), {});
          inputs.push(groupInput);
          group[key] = groupForm;
        }
      }
    });

    return [new FormGroup(group), inputs];
  }

  private parseSimpleType(property: any, options: any): InputBase {
    if (property?.type === 'boolean') {
      return new ToggleInput(options);
    } else if (property?.type === 'string' && (property?.oneOf?.[0]?.format === 'date') || (property?.oneOf?.[0]?.format === 'date-time')) {
      return new DateInput(options);
    } else if (property?.type === 'string' && !!property.enum && property.enumNames) {
      const input = new ChoiceInput(options);
      input.choices = property.enum.map((item: string, index: number) => ({
        key: item,
        label: property.enumNames[index],
      }));
      return input;
    } else if (property?.type === 'integer' || property?.type === 'number') {
      return new NumberInput(options);
    } else if (property?.type === 'array' && property?.items?.required?.includes('media_location')) {
      return new ImageInput(options);
    } else if (property?.maxLength > 100) {
      const input = new TextAreaInput(options);
      input.maxLength = property?.maxLength;
      return input;
    } else {
      const input = new TextInput(options);
      input.maxLength = property?.maxLength;
      return input;
    }
  }

  getNonIgnoredKeys(obj?: { [key: string]: any }): string[] {
    if (!obj) {
      return [];
    }
    return Object.keys(obj).filter(key => !IGNORED_COLUMNS.includes(key));
  }

  private getValue(product: any, key: string, type: string = 'array', valueProp: string = 'value'): any {
    if (Array.isArray(product[key])) {
      return type === 'array' ? String(product[key][0]?.[valueProp]) : String(product[key]?.[valueProp]);
    }
    if (product[key]?.value) {
      return product[key]?.value;
    }
    return product[key];
  }

  clearFormErrors(form: FormGroup) {
    form.setErrors(null);
    Object.keys(form.controls).forEach(key => {
      const element: AbstractControl | null = form.get(key);
      if (element) {
        element.setErrors(null);
      }
      if (element instanceof FormGroup) {
        const group: FormGroup = element as FormGroup;
        this.clearFormErrors(group);
      }
    });
  }

  /**
   * TODO
   * the whole logic in this method should be refactored - the JSON should be built from scratch, using the
   * form values, instead of setting/removing fields in the existing JSON.
   */
  mapDirtyKeysToJson(
    productJson: any,
    options: {
      values: any,
      parents: string[],
      inputs: { [key: string]: InputBase }
    }) { // TODO this method needs refactoring a lot
    const { inputs, parents, values } = options;
    if (!values) {
      return;
    }

    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'object' && !(values[key] instanceof Date)) {
        parents.push(key);
        this.mapDirtyKeysToJson(productJson, {
          ...options,
          values: values[key],
        });
        parents.pop();
        return;
      }
      let value = values[key];
      if (value instanceof Date) {
        value = format(value, 'yyyy-MM-dd');
      }
      let pathInSchema;
      let controlType;
      if (parents.length) {
        // traverse the parents
        let group: any = inputs;
        parents.forEach(parent => {
          group = (group[parent] as GroupInput).groupItems;
        });
        pathInSchema = group[key].pathInSchema;
        controlType = group[key].controlType;
      } else {
        pathInSchema = inputs[key].pathInSchema;
        controlType = inputs[key].controlType;
      }
      const currentValue = get(productJson, pathInSchema);
      if (value === undefined && currentValue === undefined) {
        return;
      }
      if (value === undefined || value === null || value === '') {
        const parentPath = pathInSchema.length ? pathInSchema.slice(0, -1) : [];
        this.removeChild(productJson, key, parentPath);
        return;
      }
      if (controlType === InputControlType.Number && Number(value) !== Number.NaN) {
        value = Number(value);
      }
      set(productJson, pathInSchema, value);
    });
  }

  removeChild(productJson: any, key: string, parents: string[] = []) { // TODO refactor this monster
    if (parents.length === 0) {
      delete productJson[key];
      return;
    }
    let obj = get(productJson, parents);
    if (obj && Array.isArray(obj) && obj.length) {
      const item = obj[0];
      if (item[key]) {
        delete item[key];
      }
      // if no more children left - remove the parent
      if (!Object.keys(item).length) {
        const newKey = parents.pop();
        if (newKey) {
          this.removeChild(productJson, newKey, parents);
        }
      }
    } else if (obj) {
      if (obj[key]) {
        delete obj[key];
      } else { // no item with key, could be that path is messed up
        const indexOfKey = parents.indexOf(key); // check if key exists in the path and use that
        if (indexOfKey > -1) {
          parents = parents.slice(0, indexOfKey);
          this.removeChild(productJson, key, parents);
        }
      }
    }

    // iterate parents and remove if empty
    while (obj && !Object.keys(obj).filter(key => !IGNORED_COLUMNS.includes(key)).length) {
      const newKey = parents.pop();
      obj = get(productJson, parents);
      if (newKey !== undefined) {
        this.removeChild(productJson, newKey, parents);
      }
    }
  }

  getDirtyValues(form: any) {
    let dirtyValues: any = {};

    Object.keys(form.value).forEach(key => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }
}
