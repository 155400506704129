import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/services/local-storage.service';

export const UI_STATE_STORAGE_KEY = 'pup_marketplaces_ui_state_storage_key';

export interface TableSortingState {
  [column: string]: {
    ascending: boolean;
    descending: boolean;
  }
}

export interface UiState {
  marketplaceSidebarOpen: boolean;
  sorting: {
    listings?: TableSortingState;
    products?: TableSortingState;
    errorsCode?: TableSortingState;
    errorsProduct?: TableSortingState;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UiStateService {

  state: UiState = {
    marketplaceSidebarOpen: true,
    sorting: {}
  };

  constructor(public localStorageService: LocalStorageService) {
    const defaultState = {
      marketplaceSidebarOpen: true
    };

    const savedState = localStorageService.restoreData(UI_STATE_STORAGE_KEY);
    this.state = savedState || defaultState;

    // TODO this is only required temporarily, until the correct new state is resaved in localStorage
    if (!this.state.sorting) {
      this.state.sorting = {};
    }
  }

  saveMarketplaceSidebarOpen(value: boolean) {
    this.state.marketplaceSidebarOpen = value;
    this.localStorageService.saveData(UI_STATE_STORAGE_KEY, this.state);
  }

  setProductsSorting(attribute: string, ascending: boolean) {
    if (!this.state?.sorting?.products) {
      this.state.sorting.products = {};
    }
    this.setSorting(this.state.sorting.products, attribute, ascending);
  }

  setListingsSorting(attribute: string, ascending: boolean) {
    if (!this.state?.sorting?.listings) {
      this.state.sorting.listings = {};
    }
    this.setSorting(this.state.sorting.listings, attribute, ascending);
  }

  setErrorsByCodeChange(attribute: string, ascending: boolean) {
    if (!this.state?.sorting?.errorsCode) {
      this.state.sorting.errorsCode = {};
    }
    this.setSorting(this.state.sorting.errorsCode, attribute, ascending);
  }

  setErrorsByProductChange(attribute: string, ascending: boolean) {
    if (!this.state?.sorting?.errorsProduct) {
      this.state.sorting.errorsProduct = {};
    }
    this.setSorting(this.state.sorting.errorsProduct, attribute, ascending);
  }

  private setSorting(list: TableSortingState, attribute: string, ascending: boolean) {
    Object.keys(list).forEach(key => { // TODO remove this if api supports more than 1 sorting at a time
      if (key !== attribute) {
        delete list[key];
      }
    });

    if ((ascending && list?.[attribute]?.ascending) || (!ascending && list?.[attribute]?.descending)) {
      delete list[attribute];
      return;
    }
    list[attribute] = {
      ascending: ascending,
      descending: !ascending
    }
  }
}
