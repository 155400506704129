<app-header></app-header>
<pup-content>
  <pup-heading [level]="2">{{ 'marketplaces.products.page-title' | translate }}</pup-heading>

  <pup-paragraph class="filter-box">
    <pup-input
      class="mr-6 products--search"
      [clearable]="true"
      [disabled]="loading"
      [placeholder]="'marketplaces.products.search' | translate"
      [(value)]="query"
      (enter)="resetPaginationAndLoad()"
      (clear)="resetPaginationAndLoad()"
    >
      <pup-icon class="search-trigger" path="line/ic-search" (click)="loadProducts()"></pup-icon>
    </pup-input>

    <ng-content select=".extra-controls"></ng-content>

    <pup-button [variant]="ButtonVariant.DarkLink" (click)="showCategorySelectionModal()">
      <pup-icon path="line/ic-category"></pup-icon>
      <span *ngIf="this.categoryService.selectedCategories?.length">
        ({{ this.categoryService.selectedCategories?.length }})&nbsp;
      </span>
      {{ 'marketplaces.products.categories' | translate }}
    </pup-button>

    <pup-button [variant]="ButtonVariant.DarkLink" (click)="showAttributeSelectionModal()">
      <pup-icon path="line/ic-setup"></pup-icon>
      {{ 'marketplaces.products.attributes' | translate }}
    </pup-button>
  </pup-paragraph>
  <span class="search-count" *ngIf="searchResults && products.length">
      {{ 'marketplaces.products.search-results-count' | translate: { count: pagination.totalCount } }}
    </span>

  <app-product-table
    [currentPage]="pagination.currentPage"
    [displayedAttributes]="categoryService.displayedProductAttributes"
    [lockedAttributes]="categoryService.lockedAttributes"
    [items]="products"
    [loading]="loading"
    [(marked)]="selectedProductIds"
    [(allSelected)]="allProductsSelected"
    [total]="pagination.totalCount"
    [searchResults]="searchResults"
    (paginationChange)="handlePaginationChange($event)"
    (select)="openProductDetails($event)"
    (attributesReorder)="reorderAttributes($event)"
    [sorting]="uiStateService.state?.sorting?.products"
    (sortingChange)="handleSortingChange($event)"
    [queryResults]="queryResults"
    placeholderImgSrc="/assets/visuals/placeholders/empty_product-table.svg"
    [noResultsFoundMessage]="'marketplaces.errors.no-results.message'"
    [noResultsFoundDescription]="'marketplaces.products.no-results.description'"
  >
    <div class="actions">
      <div class="vertical-divider"></div>
      <pup-button [disabled]="disabled" (click)="addToMarketplace()" [variant]="ButtonVariant.Secondary">
        {{ "marketplaces.products.add-to-marketplace" | translate }}
      </pup-button>
    </div>
  </app-product-table>
</pup-content>

