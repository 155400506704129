<pup-floating-label *ngIf="input?.label" [optional]="!input?.required">{{ input?.label }}</pup-floating-label>
<pup-dropdown-select [disabled]="disabled" (click)="touch()" [invalid]="invalid" [errorMessage]="hint">
  <pup-paragraph label>{{ valueLabel | translate }}</pup-paragraph>
  <pup-dropdown-menu-item (select)="select()">
    <span>{{ 'marketplaces.form.toggle.undefined' | translate }}</span>
  </pup-dropdown-menu-item>
  <pup-dropdown-menu-item (select)="select(item)" *ngFor="let item of items;">
    <span>{{ item.label }}</span>
  </pup-dropdown-menu-item>
</pup-dropdown-select>
