import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EXTERNAL_AUTH_RESPONSE_TYPE } from '@app/interfaces/externalAuthResponse';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalAuthService {

  window!: Window;

  constructor(@Inject(DOCUMENT) private document: Document, private translateService: TranslateService) {
    if (this.document.defaultView) {
      this.window = this.document.defaultView
    }
  }

  authenticateAmazon(options: { marketplaceId: number, countryId: number, type: string }) {
    const { marketplaceId, countryId, type } = options;
    const token = localStorage.getItem('access_token');

    this.openExternalAuthWindow(
      `${environment.apiUrl}/marketplaces/${marketplaceId}/authorize/amazon/${type}?bearer=${token}`,
      this.translateService.instant('marketplaces.amazon.authentification')
    );

    return new Promise<void>((resolve, reject) => {
      this.window.addEventListener('message', (event) => {
        if (event.data?.type !== EXTERNAL_AUTH_RESPONSE_TYPE) {
          return;
        }
        const success = event.data.success as boolean;

        if (success) {
          resolve();
        } else {
          reject();
        }
      });
    })
  }

  private openExternalAuthWindow(url: string, pageTitle: string) {
    const width = 1024;
    const height = 768;

    const left = (this.window.innerWidth - width) * 0.5;
    const top = (this.window.innerHeight - height) * 0.5;

    const ref = this.window.open(url, pageTitle, `top=${top},left=${left},width=${width},height=${height}`);
  }
}
