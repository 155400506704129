<ng-container *ngIf="marketplaces && marketplaces.length">
  <span class="marketplace ml-2">Amazon</span>
  <img
    *ngFor="let marketplace of marketplaces"
    default-src
    [src]="getFlagImageSrc(marketplace)"
    [tooltip]="getCountryTranslateKey(marketplace.country.code) | translate"
    [tooltipPlacement]="TooltipPlacement.Top"
  >
</ng-container>
