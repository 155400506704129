import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsPage } from '@app/pages/products/products.page';
import { MarketplacesPage } from '@app/pages/marketplaces/marketplaces.page';
import { ListingsPage } from '@app/pages/marketplace/listings/listings.page';
import { ProductDetailsPage } from '@app/pages/product-details/product-details.page';
import { MarketplaceComponent } from '@app/pages/marketplace/marketplace.component';
import { MarketplaceErrorsPage } from '@app/pages/marketplace/errors/marketplace-errors.page';
import { MarketplaceDashboardPage } from '@app/pages/marketplace/dashboard/marketplace-dashboard.page';
import { MarketplaceOrdersPage } from '@app/pages/marketplace/orders/marketplace-orders.page';
import { ListingDetailsPage } from '@app/pages/marketplace/listing-details/listing-details.page';
import { MarketplaceErrorDetailsPage } from '@app/pages/marketplace/error-details/marketplace-error-details.page';
import { AmazonAuthSuccessComponent } from '@app/components/authentication/amazon/amazon-auth-success.component';
import { AmazonAuthFailedComponent } from '@app/components/authentication/amazon/amazon-auth-failed.component';
import { AuthGuard } from '@app/services/auth-guard.service';
import { LoginPage } from '@app/pages/login/login.page';
import { MarketplaceSettingsPage } from '@app/pages/marketplace/settings/marketplace-settings.page';
import { AddMarketplacePage } from '@app/pages/wizards/add-marketplace/add-marketplace.page';
import { CategorySelectorPage } from '@app/pages/wizards/category-selector/category-selector.page';
import { AttributeSelectorPage } from '@app/pages/wizards/attribute-selector/attribute-selector.page';

const routes: Routes = [
  {
    path: '', canActivate: [AuthGuard], children: [
      {
        path: '',
        component: ProductsPage
      },
      {
        path: 'products/:productId',
        component: ProductDetailsPage
      },
      {
        path: 'marketplaces',
        component: MarketplacesPage
      },
      {
        path: 'marketplaces/:marketplaceId',
        component: MarketplaceComponent,
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          { path: 'listings', component: ListingsPage },
          { path: 'listings/:productId', component: ListingDetailsPage },
          { path: 'errors', component: MarketplaceErrorsPage },
          { path: 'errors/:errorId', component: MarketplaceErrorDetailsPage },
          { path: 'dashboard', component: MarketplaceDashboardPage },
          { path: 'orders', component: MarketplaceOrdersPage },
          { path: 'settings', component: MarketplaceSettingsPage },
        ],
      },
      {
        path: 'authorize/amazon/success',
        component: AmazonAuthSuccessComponent
      },
      {
        path: 'authorize/amazon/error',
        component: AmazonAuthFailedComponent
      },
      {
        path: 'add-marketplace',
        component: AddMarketplacePage
      },
      {
        path: 'select-category',
        component: CategorySelectorPage
      },
      {
        path: 'select-attributes',
        component: AttributeSelectorPage,
      },
      {
        path: 'select-attributes/:category',
        component: AttributeSelectorPage,
      },
    ]
  },
  {
    path: 'login',
    component: LoginPage
  },
  { path: '**', redirectTo: '', },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
