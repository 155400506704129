import { Component, ElementRef } from '@angular/core';
import { ButtonVariant } from 'lib-juniper';

import { Marketplace } from '@app/interfaces/marketplace';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, GroupedError, Pagination } from '@app/interfaces';
import { MarketplacePage } from '@app/pages/marketplace/marketplace.page';
import { ErrorByListing } from '@app/interfaces/errors';
import { PaginatedResponse } from '@app/services/products.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { ListingsService } from '@app/services/listings.service';
import { first, takeUntil } from 'rxjs/operators';
import { UiStateService } from '@app/services/ui-state.service';

enum ErrorType {
  Listings,
  ErrorCodes,
}

@Component({
  selector: 'app-marketplace-errors-page',
  templateUrl: './marketplace-errors.page.html',
  styleUrls: ['../../page.scss', '../marketplace.page.scss', './marketplace-errors.page.scss']
})
export class MarketplaceErrorsPage extends MarketplacePage {

  errors: GroupedError[] | ErrorByListing[] = [];
  markedErrors: string[] = [];
  query: string = '';

  marketplace?: Marketplace;

  loading = false;

  errorType: ErrorType = ErrorType.Listings;

  pagination: Pagination = {
    currentPage: DEFAULT_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
  };

  ButtonVariant = ButtonVariant;
  ErrorType = ErrorType;
  queryResults: boolean = true;

  constructor(
    ref: ElementRef,
    public router: Router,
    public marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    public activatedRoute: ActivatedRoute,
    public uiStateService: UiStateService,
  ) {
    super(ref, router, marketplacesService, activatedRoute);
  }

  get listingErrors(): ErrorByListing[] {
    return this.errors as ErrorByListing[];
  }

  get codeErrors(): GroupedError[] {
    return this.errors as GroupedError[];
  }

  ngOnInit(): void {
    this.loading = true;
    this.marketplace$.pipe(takeUntil(this.destroyed$)).subscribe((marketplace) => {
      if (marketplace) {
        this.marketplace = marketplace;
        this.loadErrors();
      }
    });
  }

  loadErrors() {
    if (!this.marketplace) {
      return;
    }
    this.loading = true;
    const { pageSize, currentPage } = this.pagination;

    const sorting = this.errorType === ErrorType.ErrorCodes ?
      this.uiStateService.state?.sorting?.errorsCode :
      this.uiStateService.state?.sorting?.errorsProduct;

    const options = {
      pageSize,
      currentPage,
      sorting,
      marketplaceId: this.marketplace.id,
    };

    const query = this.query?.length ? this.query : undefined;
    const stream$: Observable<PaginatedResponse<ErrorByListing> | PaginatedResponse<GroupedError>> = this.errorType === ErrorType.Listings ?
      this.listingsService.fetchErrorsByListing(options, query) :
      this.listingsService.fetchErrorsByCode(options, query);

    const loadingErrorType = this.errorType;

    stream$.pipe(first()).subscribe(
      ({ data, meta }) => {
        if (loadingErrorType !== this.errorType) { // TODO a better approach would be to cancel a subscription, but would require more refactoring
          return;
        }
        this.errors = data;
        this.pagination = meta;
        this.loading = false;

        this.queryResults = !(this.query && !this.errors.length);
      }
    );
  }

  handlePaginationChange(pagination: Partial<Pagination>) {
    this.pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;
    this.pagination.currentPage = pagination.currentPage || DEFAULT_CURRENT_PAGE;

    this.loadErrors();
  }

  openProductErrorDetails(errorId: number) {
    this.router.navigate([errorId], { relativeTo: this.activatedRoute });
  }

  toggleErrorType(errorType: ErrorType) {
    this.errorType = errorType;
    this.loadErrors();
  }

  handleSortingChange(change: { ascending: boolean, column: string }) {
    this.errorType === ErrorType.ErrorCodes ?
      this.uiStateService.setErrorsByCodeChange(change.column, change.ascending) :
      this.uiStateService.setErrorsByProductChange(change.column, change.ascending);
    this.loadErrors();
  }
}
