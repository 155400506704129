import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant, JuniperComponent } from 'lib-juniper';

export type Switcher = 'All' | 'System';

@Component({
  selector: 'app-button-or-input',
  templateUrl: './button-or-input.component.html',
  styleUrls: ['./button-or-input.component.scss']
})
export class ButtonOrInputComponent extends JuniperComponent {

  ButtonVariant=  ButtonVariant;

  originalValue: number = 0;
  value: number = 0;

  @Input('value')
  set setValue(value: number) {
    this.value = value;
    this.originalValue = value;
  }

  @Output()
  valueUpdate: EventEmitter<{ from: number, to: number }> = new EventEmitter<{ from: number, to: number }>()

  editMode: boolean = false;

  toggleEditMode() {
    this.editMode = true;
  }

  save() {
    this.valueUpdate.emit({ from: this.originalValue, to: this.value });
    this.value = this.originalValue;
    this.editMode = false;
  }

  cancel() {
    this.value = this.originalValue;
    this.editMode = false;
  }
}
