<app-header></app-header>
<pup-content>
  <ng-container *ngIf="product; else spinner">
    <pup-breadcrumbs home="/" [navigate]="navigate">
      <pup-breadcrumb url="/">
        <span>{{ 'marketplaces.breadcrumbs.products' | translate }}</span>
      </pup-breadcrumb>
      <pup-breadcrumb><span class="sku">{{ product.sku }}</span></pup-breadcrumb>
    </pup-breadcrumbs>

    <pup-heading [level]="2">
      <div>
        <span class="sku">{{ product.sku }}</span> - {{ product.title }}
      </div>
      <div class="controls-container">
        <pup-button [variant]="ButtonVariant.Secondary" (click)="saveProduct()">
          {{ 'marketplaces.product-listing-details-page.save' | translate }}
        </pup-button>
        <pup-button (click)="addToMarketplace()" [variant]="ButtonVariant.Secondary" [disabled]="!regions?.length">
          {{ 'marketplaces.products.add-to-marketplace' | translate }}
        </pup-button>
      </div>
    </pup-heading>

    <div class="main-container">
      <div class="side-container">
        <div class="side-menu">
          <pup-menu class="menu" *ngIf="initDone">
            <ng-container *ngIf="listingValidationService.errorCountByGroup$ | async as groupErrorCounts">
            <pup-menu-item
              (select)="scrollToTop()"
              [selected]="selectedMenuIndex === 0"
            >
              {{ 'marketplaces.marketplace.listing-details.menu-item.main' | translate }}
              <pup-badge *ngIf="groupErrorCounts.main">{{ groupErrorCounts.main }}</pup-badge>
            </pup-menu-item>
            <pup-menu-item
              *ngFor="let menu of menuItems; let index = index"
              (select)="scrollToIndex(index)"
              [selected]="selectedMenuIndex === index + 1"
            >
              {{ menu.label }}
              <pup-badge
                *ngIf="groupErrorCounts[menu.key]"
              >
                {{ groupErrorCounts[menu.key] }}
              </pup-badge>
            </pup-menu-item>
            </ng-container>
          </pup-menu>
          <ng-container *ngIf="requiredCount">
            <pup-floating-label>{{ 'marketplaces.marketplace.listing-details.filter' | translate }}</pup-floating-label>
            <pup-select [variant]="SelectVariant.Multiple">
              <pup-select-item [selected]="onlyRequired" (select)="toggleRequired()"
              >
                {{ "marketplaces.marketplace-product-details.required" | translate: { count: requiredCount } }}
              </pup-select-item>
              <pup-select-item [selected]="onlyOptional" (select)="toggleOptional()"
              >
                {{ "marketplaces.marketplace-product-details.optional" | translate: { count: inputsArray.length - requiredCount } }}
              </pup-select-item>
            </pup-select>
          </ng-container>
        </div>
      </div>
      <div class="form-container" #formContainer  (scroll)="onFormScroll($event)">
        <app-dynamic-form
          [form]="form"
          [inputs]="inputsArray"
          [onlyOptional]="onlyOptional"
          [onlyRequired]="onlyRequired"
        ></app-dynamic-form>
      </div>
      <div class="marketplaces-container" *ngIf="product.marketplaces?.length">
        <pup-paragraph>{{ 'marketplaces.products.added-to-marketplace' | translate }}</pup-paragraph>
        <div class="marketplace-list">
          <app-country-tag
            *ngFor="let marketplace of product.marketplaces"
            [marketplace]="marketplace"
            [showLogo]="true"
            [vendorsCentral]="false"
            [sellersCentral]="false"
          ></app-country-tag>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #spinner>
    <pup-loading-visual></pup-loading-visual>
  </ng-template>
</pup-content>
