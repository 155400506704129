<pup-modal (close)="dismiss()">

  <ng-container title>
    <pup-image class="heading-image" [placeholderVariant]="ImagePlaceholderVariant.Big"  src="../../../assets/visuals/intro-modal/intro_modal_{{currentPage}}.svg"></pup-image>

    <label (click)="dismiss()" class="close-label">
      {{ 'marketplaces.modal.greetings-info.skip' | translate }}
    </label>
    <pup-progress-indicator [current]="currentPage" [total]="pages">
    </pup-progress-indicator>
    <pup-heading [level]="2" class="intro-modal-heading">{{ title | translate }}</pup-heading>
  </ng-container>

  <pup-paragraph class="intro-modal-content">{{ content | translate }}</pup-paragraph>

  <pup-button (click)="nextPage()" *ngIf="currentPage < pages">
    {{ 'marketplaces.modal.greetings-info.continue' | translate }}
  </pup-button>

  <pup-button (click)="dismiss()" *ngIf="currentPage === pages">
    {{ 'marketplaces.modal.greetings-info.finish' | translate }}
  </pup-button>
</pup-modal>
