import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '@app/services/products.service';
import { MarketplacesService } from '@app/services/marketplaces.service';
import { Page } from '@app/pages/page';
import { Product } from '@app/interfaces';
import {
  MarketplaceSelectModal
} from '@app/components/modals/marketplace-selection/marketplace-select-modal.component';
import { ModalService, SelectVariant, ToastService, TooltipPlacement } from 'lib-juniper';
import { TranslateService } from '@ngx-translate/core';
import { Marketplace } from '@app/interfaces/marketplace';
import { ListingsService } from '@app/services/listings.service';
import { ListingValidationService } from '@app/services/listing-validation.service';
import { DynamicFormComponent } from '@app/components/dynamic-form/dynamic-form.component';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { InputBase } from '@app/components/dynamic-form/inputs/input-base';
import { FormsService } from '@app/services/forms.service';
import { GroupInput } from '@app/components/dynamic-form/inputs/input-group';
import { BehaviorSubject, Subject } from 'rxjs';
import { CategoryService } from '@app/services/category.service';

// TODO refator duplications between this and ListingDetailsPage
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.page.html',
  styleUrls: ['../page.scss', '../product-details-form.scss', './product-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsPage extends Page implements OnInit {

  regions: string[] = [];
  regionMarketplaces: Record<string, Marketplace[]> = {};

  productId!: string;
  product?: Product;
  productJson: any;

  onlyOptional: boolean = false;
  onlyRequired: boolean = false;
  luggage: boolean = true;
  requiredCount: number = 0;

  formComponent!: DynamicFormComponent;

  @ViewChild(DynamicFormComponent, { static: false })
  set setFormComponent(formComponent: DynamicFormComponent) {
    if (formComponent && !this.formComponent) {
      this.formComponent = formComponent;
      this.headingScrollTops = this.formComponent.getHeadingTopsMap();
    }
  }

  @ViewChild('formContainer', { read: ElementRef })
  formContainer!: ElementRef;

  form: FormGroup = new FormGroup({});
  inputs: { [key: string]: InputBase } = {};
  inputsArray: InputBase[] = [];
  headingScrollTops: number[] = [];
  schema: any;
  schemaMeta: any;

  SelectVariant = SelectVariant;
  TooltipPlacement = TooltipPlacement;

  scroll$: Subject<number> = new BehaviorSubject<number>(0);
  selectedMenuIndex: number = 0;

  initDone: boolean = false;

  constructor(
    public router: Router,
    public marketplacesService: MarketplacesService,
    private listingsService: ListingsService,
    ref: ElementRef,
    private activatedRoute: ActivatedRoute,
    private productsService: ProductsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService,
    public listingValidationService: ListingValidationService,
    private formsService: FormsService,
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService,
  ) {
    super(ref, router, activatedRoute);
  }

  async ngOnInit() {
    this.loading = true;
    this.productId = this.activatedRoute.snapshot.params.productId;
    const marketplaces: Marketplace[] = await this.marketplacesService.fetchActiveMarketplaces().toPromise();
    const { regions, marketplacesByRegion } = this.marketplacesService.splitToRegions(marketplaces);

    this.regions = regions;
    this.regionMarketplaces = marketplacesByRegion;
    this.loadProduct();
  }

  ngAfterViewInit(): void {
    this.initDone = true;
    this.cdr.detectChanges();
  }

  async addToMarketplace() {
    const modalRef = this.modalService.open(MarketplaceSelectModal);
    modalRef.instance.regions = this.regions;
    modalRef.instance.regionMarketplaces = this.regionMarketplaces;
    modalRef.instance.count = 1;
    modalRef.instance.existingMarketplaceIds = this.product?.marketplaces.map(({ id }) => id);

    const selectedMarketplaces: number[] = await modalRef.result;

    for (const marketplaceId of selectedMarketplaces) {
      await this.listingsService.listSingleProduct(marketplaceId, this.productId).toPromise();
    }

    this.toastService.success(this.translateService.instant(
      'marketplaces.toast.add-to-marketplace-success-single',
      { marketplaces: selectedMarketplaces.length }
    ));
    await this.loadProduct();
  }

  async loadProduct() {
    this.loading = true;
    const [product, productJson] = await Promise.all([
      // this.listingsService.getSingleListing(this.productId).toPromise(),
      // this.listingsService.getSingleListingJson(this.productId).toPromise(),
      this.productsService.getSingleProduct(this.productId).toPromise(),
      this.productsService.getSingleProductJson(this.productId).toPromise(),
    ]);
    const [schema, schemaMeta] = await Promise.all([
      this.categoryService.fetchCategorySchema(1, product?.category).toPromise(),
      this.categoryService.fetchCategorySchemaMeta(1, product?.category).toPromise(),
    ]);

    this.schema = schema;
    this.schemaMeta = schemaMeta;

    this.product = product;
    this.productJson = productJson;

    const [form, inputs] = this.formsService.getFormAndInputs(productJson, schema);
    this.form = form;
    this.inputs = inputs;
    this.inputsArray = Object.values(inputs);
    this.requiredCount = Object.values(this.inputs).filter(input => input.required).length;

    this.loading = false;
    this.cdr.detectChanges();
    // this.validatorService.validate(product);
  }

  saveProduct() {
    const values = this.formsService.getDirtyValues(this.form);
    this.formsService.mapDirtyKeysToJson(this.productJson, {
      values,
      parents: [],
      inputs: this.inputs,
    });
    this.productsService.saveProduct(this.productId, this.productJson).subscribe(() => {
      this.toastService.success(this.translateService.instant('marketplaces.toast.product-details-updated'));
    });
  }

  toggleOptional() {
    this.onlyOptional = !this.onlyOptional;
  }

  toggleRequired() {
    this.onlyRequired = !this.onlyRequired;
  }

  scrollToIndex(index: number) {
    this.formContainer.nativeElement.scrollTop = this.headingScrollTops[index + 1] - this.headingScrollTops[0] + 200;
    this.selectedMenuIndex = index + 1;
    this.cdr.detectChanges();
  }

  scrollToTop() {
    this.formContainer.nativeElement.scrollTop = 0;
    this.cdr.detectChanges();
  }


  getScrollTop(index: number) {
    return this.headingScrollTops[index];
  }

  get menuItems(): { key: string; label: string }[] {
    return Object.values(this.inputs)
      .filter(input => this.isShown(input) && input instanceof GroupInput)
      .map(input => ({ label: input.label, key: input.key }));
  }

  private isShown(input: InputBase) {
    return (this.onlyRequired && input.required) || (this.onlyOptional && !input.required) || (!this.onlyOptional && !this.onlyRequired);
  }

  calculateSelectedMenuItem(scroll: number) {
    let index = 0;
    const scrollWithOffset = scroll + this.headingScrollTops[0] - 100; // TODO offset shouldn't be needed - there's a bug most likely somewhere
    while (scrollWithOffset > this.getScrollTop(index + 1) && index < this.menuItems.length) {
      index += 1;
    }
    this.selectedMenuIndex = index;
  }

  onFormScroll(event: Event) {
    const scroll = (event.target as HTMLElement).scrollTop || 0;
    this.scroll$.next(scroll);
  }
}
