import { InputControlType } from '@app/interfaces';

export class InputBase {
  value: any; // TODO should not be any
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: InputControlType;
  type: string;
  options: { key: string, value: string }[];
  pathInSchema: (string|number)[]; // TODO this should go away

  constructor(options: {
    value?: any;
    key?: string;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: InputControlType;
    type?: string;
    pathInSchema?: string[];
    options?: { key: string, value: string }[];
  } = {}) {
    this.value = options.value || '';
    this.key = options.key || '';
    this.label = options.label || this.key;
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || InputControlType.Text;
    this.type = options.type || '';
    this.options = options.options || [];
    this.pathInSchema = options.pathInSchema || [this.key];
  }
}
